@import '../../../styles/colors';

.super-live {
    border-top: 1px solid $colorAlto;
    padding-top: 2rem;
    margin-top: 2rem;
    padding-bottom: 0.5rem;
}

.span {
    padding-left: 0.5rem;
}
