@import '../../../../styles/colors';

.wrapper {
    width: 4rem;
    height: 4rem;
    border: 2px solid $colorAlto;
    position: relative;
    border-radius: 4rem;
}

.icon {
    color: $colorAlto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 2.4rem;
    height: 2.4rem;
}
