@import '../../../styles/rwd';

.subscription-card,
.settings-card,
.activity-card {
    @include media('<=phone') {
        height: calc(100vh - 6rem); // Full height minus height of the header
    }
}
.wifi-zone-card,
.geofences-card,
.history-card {
    @include media('<=phone') {
        max-height: 60vh;
    }
}

.card {
    @include media('<=phone') {
        padding: 0 1rem;
    }
}
