@import '../../../styles/colors';

.container {
    border-radius: 1rem;
    border: 1px solid $colorAlto;
    position: relative;
}

.field {
    display: block;
    width: 100%;
    background: transparent;
    height: 5rem;
    border: none;
    -webkit-appearance: none;
    box-shadow: none;
    font-size: 1.4rem;
    padding: 0 1rem;

    &:focus,
    &:active {
        box-shadow: none;
        outline: none;
    }

    &.small {
        height: 4rem;
    }
}

.arrow {
    right: 1.5rem;
    top: 0;
    height: 100%;
    position: absolute;
    pointer-events: none;
}

.label {
    color: $colorDoveGray;
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
}
