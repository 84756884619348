@import '../../../styles/rwd';

.headline {
    margin-bottom: 2.5rem;
}

.image {
    margin: 3rem auto;
    height: 18rem;
    width: auto;

    @include media('<=phone') {
        margin: 0 auto;
    }
}

.button {
    margin: 5rem auto 0;
    width: 20rem;
    display: block;

    @include media('<=phone') {
        margin: 0 auto;
    }
}
