@import '../../../styles/rwd';
@import '../../../styles/colors';

.headline {
    margin-bottom: 3rem;

    @include phoneHidden();
}

.radio {
    margin-bottom: 1.5rem;
    display: block;
}

.reset {
    margin-left: 1.5rem;

    @include media('<=phone') {
        margin-left: 0;
        margin-top: 2rem;
        width: 100%;
        display: block;
    }
}

.submit {
    @include media('<=phone') {
        display: block;
        width: 100%;
    }
}

.label {
    max-width: 569px;
    color: $colorMuteGray;
    font-weight: 500;
}

.emailList {
    display: block;
    width: 100%;
    max-width: 752px;
    border: solid 1px #D9D9D9;
    margin: 30px 0px;
    padding: 10px;
    
    @include media('<=phone') {
        margin: 10px 0px;
        padding: 5px;
    }

    
    div, label{
        display: block;
        margin: 8px 0px;

        p, div, a{
            display: inline-block;
        }
        
        p {
            width: 70%;
            @include media('<=phone') {
                width: 65%;
            }
        }
        
        div {
            margin: 8px max(calc(15% - 25px), 37px);
            @include media('<=phone') {
                margin: 8px 10%;
            }
            @include media('<=602px') {
                margin: 8px 16px;
            }
        }
        
        a {
            min-width: 124px;
            margin: 8px max(calc(15% - 62px), 0px);
            text-align: center;
            @include media('<=phone') {
                min-width: 100px;
                margin: 8px 0px;
            }
            @include media('<=602px') {
                min-width: 100px;
                margin: 8px 0px;
            }
        }
    }
    
    div.headrow {
        margin-top: 0px;
        width: 100%;
        
        p {
            font-weight: 700;
            color: black;
            margin-top: 0px;
            @include media('<=602px') {
                width: calc(100% - 110px);
            }
        }

    }
}

.spacerBefore {
    border-top: 1px solid #B8B8B8;
    padding-top: 35px;
    margin-top: 40px;
}

.labelExtraInfo {
    font-size: 0.8em;
}

.control {
    margin-bottom: 1.5rem;
}

.control-radio {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    div span {
        color: black;
    }
}

.radioSuccessMessage {
    color: #99ca3b;
}

.control-title {
    font-family: Lato !important;
    font-weight: bold;
    margin-bottom: 0;
}

.preferredMetric {
    margin: 0 1rem;
}

.values {
    color: black;
    margin-right: 1rem;
}