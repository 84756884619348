@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.attributes-list {
    margin-bottom: 1.5rem;
}

.tracker-comms {
    padding-top: 1rem;
    border-top: 1px solid $colorAlto;
    @include media('<=phone') {
        border-top: none;
        margin: 2rem;
    }
}

.tracker-details {
    padding-top: 1rem;
    border-top: 1px solid $colorAlto;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    @include media('<=phone') {
        margin: 2rem;
    }
}

.actions-list {
    padding-bottom: 1rem;
    border-bottom: 1px solid $colorAlto;
}
