@import '../../../../styles/colors';

.languageFieldset {
    border: none;
    margin: 16px 10px;
    padding: 10px;

    legend {
        color: $colorMuteGray;
    }
}

.radio {
    margin-bottom: 1.5rem;
    display: block;
    div span {
        color: black;
    }
}
