@import '../../../styles/colors';
@import '../../../styles/rwd';

.container {
    margin-top: 8rem;
    max-width: 100rem;
    padding-bottom: 2.5rem !important;
}

.svg-success {
    color: #99ca3b;
    margin-left: 1rem;
    transform: scale(1.5);
}

.svg-error {
    color: #ff4733;
    margin-left: 1rem;
    transform: scale(1.5);
}

.buttonPosition {
    display: flex;
    justify-content: center;
    width: 20rem;
    margin: auto;
    margin-top: 5rem;

    @include media('<=phone') {
        width: 90%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 4rem;
    }
}

.widthFull {
    width: 100%;
}

.titleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.color {
    color: #ff4733;
}

.textInsurance {
    text-align: center;

    @include media('<=phone') {
        text-align: left;
    }
}
