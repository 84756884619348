@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.list {
    margin: 0;
    padding: 0;
    list-style: none;

    @include media('<=phone') {
        margin: 2rem;
    }
}

.link {
    padding: 0;
    width: 100%;
    align-items: center;
    display: flex;

    & svg {
        color: $colorSilverChalice;
    }

    &.active-link {
        color: $colorFlamingo;

        & svg {
            color: $colorFlamingo;
        }
    }
}

.marginLeft {
    margin-top: 0.5rem;
    margin-left: 1rem;
}