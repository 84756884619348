@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.summary-table {
    margin-top: 1.5rem;
}

.table-label {
    font-size: 1.2rem;
    color: $colorDoveGray;
    margin-bottom: 0.5rem;
    display: block;
}

.table-group {
    margin-bottom: 1.5rem;
    border-bottom: 1px solid $colorAlto;

    & ul {
        padding-left: 0;
        list-style-type: none;
    }
}

.table-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    align-items: center;
}

.shopping-item {
    font-size: 1.4rem;

    &.price {
        margin-left: auto;
        font-weight: bold;
        margin-right: 1rem;
    }
}
.promo-code,
.promo-code-discount {
    color: $colorFlamingo;
}
.promo-code-discount {
    font-weight: bold;
    margin-left: auto;
    margin-right: 1rem;
}
.option-title {
    font-weight: bold;
}
.option-title,
.option-description {
    display: block;
}
.upgrade-text {
    font-size: 1.2rem;
    display: block;
    line-height: 1.6rem;
    margin-bottom: 0.5rem;
}

.final-price {
    margin-top: 1.5rem;
    align-items: center;
    padding-top: 1.5rem;

    .shopping-item {
        font-weight: bold;
    }
}

.final-price-value {
    font-size: 2rem;
    font-weight: $fontWeightBold;
}

.remove-button {
    border-radius: 90%;
    color: white;
    padding: 0;
    background-color: #979797;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
}

.highlight {
    color: $colorFlamingo;
}

.hint {
    color: $colorDoveGray;
    font-size: 1.2rem;
    margin: 1rem 0 0;
}
