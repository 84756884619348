@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.trackers-list {
}
.searchResult {
    margin-top: 0;
    text-align: end;
    margin-right: 1rem;
}
.tracker-content {
    padding: 0 0 2rem 0;
}
.searchBar {
    width: 95%;
}
.searchBarContainer {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
}

.content-components {
    &.activating {
        opacity: 0.3;
    }
}
input {
    margin-bottom: 1rem;
}
.item {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    &.inactive {
        opacity: 0.5;
    }

    &.all-trackers-item {
    }
}

.activating {
    margin-bottom: 2rem;
}
