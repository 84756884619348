@import '../../../styles/layout';
@import '../../../styles/colors';
@import '../../../styles/rwd';

.page {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;

    @include media('<=phone') {
        height: auto;
    }
}

.wrapper {
    display: flex;
    flex: 1 1 auto;

    @include media('<=phone') {
        display: block;

        .left-column,
        .right-column {
            width: 100%;
            padding: 1.5rem;
        }
    }
}

.leftColumn {
    width: $sidebarWidth;
    border-right: 1px solid $colorAlto;
    padding: 4rem 1.5rem;
    @include media('<=phone') {
        border-right: none;
        padding: 9rem 1.5rem 0rem 1.5rem;
        width: auto;
    }
}

.rightColumn {
    padding: 4rem 1.5rem;
    max-width: 76rem;
    flex: 0 1 76rem;
}
