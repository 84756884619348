.createGeofence {
    margin-bottom: 2rem;
}

.geofencesList {
    margin-bottom: 2rem;
}

.geofenceItem {
    margin-bottom: 1rem;
}
