@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/rwd';

.steps {
    height: 12rem;
    display: flex;
    justify-content: space-between;

    @include media('<=phone') {
        margin-top: 2rem;
        height: auto;
    }
}

.step {
    flex: 0 0 calc(25% - 2rem);
    margin-right: 2rem;
    font-size: 1.4rem;
    color: $colorDoveGray;
    display: flex;
    align-items: center;

    &.completed {
        color: $colorAtlantis;

        .marker {
            background: #f5f9ec;
        }
    }

    &.active {
        color: white;
        position: relative;

        &::after {
            content: '';
            bottom: -1px;
            left: 0;
            width: 100%;
            position: absolute;
        }

        .marker {
            background: #2b2e37;
        }
    }

    @include media('<=phone') {
        font-size: 1.2rem;
        padding-bottom: 1rem;
    }
}

.biogaran {
    margin-top: 6rem;
    flex: 0 0 calc(25% - 2rem);
    margin-right: 2rem;
    font-size: 1.4rem;
    color: $colorDoveGray;
    display: flex;
    align-items: center;

    &.completed {
        color: $colorAtlantis;

        .marker {
            background: #f5f9ec;
        }
    }

    &.active {
        color: $colorAlto;
        position: relative;

        &::after {
            content: '';
            border-top: 1px solid $colorFlamingo;
            bottom: -1px;
            left: 0;
            width: 100%;
            position: absolute;
        }

        .marker {
            background: $colorFlamingo;
        }
    }

    @include media('<=phone') {
        font-size: 1rem;
        padding-bottom: 1rem;
    }
}

.biogaranText {
    &.active {
        color: $colorCodGray;
    }
}

.weenectText {
    color: $colorCodGray;

    &.active {
        color: #2b2e37;
    }
}

.marker {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    height: 3rem;
    width: 3rem;
    background: $colorWildSand;
    font-weight: $fontWeightBold;
    border-radius: 1.5rem;

    @include media('<=phone') {
        margin-right: 0;
        margin-right: 1rem;
    }
}