@import '../../../styles/rwd';

$gutter: 2.5rem;

.grid {
    display: flex;
    justify-content: space-between;

    &>* {
        width: 50%;
    }

    @include media('<=phone') {
        display: block;

        &>* {
            width: 100%;
            flex: 100%;
        }
    }
}