@import '../../../../styles/colors.scss';

.headline {
    margin-left: 3rem;
}

.container {
    margin-top: 8rem;
}

.hipay-form {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 520px;

    & .submit {
        margin: 10px;
        color: $colorWhite;
    }
}

.hosted-field {
    height: 40px;
    border-radius: 3px;
    background-color: #ebebeb;
    margin: 1rem 0;
    padding-left: 1rem;
}

.container-funnel {
    border: #a6a6a6 1px solid;
    border-radius: 7px;
    padding: 1rem;
}

.expiry-cvc-container {
    display: flex;
    width: 100%;
}

.cvc-container {
    margin-left: 0.3rem;
    flex-grow: 1;
}

.funnel-label {
    font-weight: bold;
}

.expiry-container {
    margin-right: 0.3rem;
    flex-grow: 1;
}

.loading {
    height: 7rem;
}