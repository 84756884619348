@import '../../../styles/colors';

.tooltip {
    position: relative;
    display: inline-block;
}

.trigger {
    cursor: pointer;
    display: inline-block;

    &:hover + .content {
        display: block;
    }
}

.content {
    display: none;
    position: absolute;
    width: 20rem;
    background: $colorWhite;
    border-radius: 2px;
    box-shadow: 0 2px 10px 0 rgba(13, 13, 13, 0.15);
    left: -10rem;
    padding: 1rem;
    top: 0;
    color: $colorDoveGray;
    font-size: 1.2rem;
    transform: translateY(calc(-100% - 0.5rem));
}
