.panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.name {
    margin-bottom: 0.5rem;
}

.form {
    padding: 1rem;
}
