@import '../../../styles/rwd';

.main-headline {
    margin-top: 2rem;
    @include media('<=phone') {
        margin-top: 2rem;
    }
    margin-bottom: 2rem;
}

.main-text {
    text-align: center;
    margin: 0 auto 4rem;
}

.heightSetter {
    margin: 10rem 0 0;
}
.typeFormContainer {
    width: 36rem;
    margin: 0 auto;
}
.animalChoicesContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 1rem auto 6rem;
}
.icon {
    margin: 3rem auto 0;
}
.iconTitleContainerSelected {
    border: 1px solid #ed6e18;
    width: 18rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 70rem;
    cursor: pointer;
}
.animalTypeSubmitButton {
    width: 36rem;
}
.iconTitleContainer {
    width: 18rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.animal-type-subtitle {
    margin-bottom: 2rem;
    font-weight: normal;
}
.animal-type-subtitle-selected {
    margin-bottom: 2rem;
    font-weight: normal;
    text-align: center;
    color: #ed6e18;
}
.grid {
    margin: 0 auto;
}

.subheadline {
    font-size: 1.6rem; // Designers should have this standardized
    margin-bottom: 1.5rem;
}

.param-block {
    margin-bottom: 1.5rem;
}

.alertPopup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.7rem;
    text-align: justify;
    text-align-last: center;
}
.tracker-options {
    display: flex;
    flex-direction: row;
    margin-bottom: 2.4rem;
}
.text-container {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}
.tracker-name {
    font-size: 1.7rem;
    margin: 0;
}
.tracker-imei {
    color: #737373;
}
.radio-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
}
