@import '../../../../styles/colors';
@import '../../../../styles/fonts';
@import '../../../../styles/rwd';

.container {
    max-width: 100%;
    padding-left: 12%;
    padding-right: 12%;
    margin: 0;

    @include media('<=phone') {
        padding-left: 5%;
        padding-right: 5%;
    }
}

.button {
    padding: 1rem
}

.inputContainer {
    margin-top: 2rem;
    width: 100%;
    font-weight: bold;

    @include media('<=phone') {
        width: 100%;
        margin-top: 2rem;
    }
}

.main-headline {
    margin-bottom: 1rem;
    text-align: start;

    @include media('<=phone') {
        width: 100%;
    }
}

.gocardless-link {
    color: $colorFlamingo;
    cursor: pointer;
}

.submit {
    color: $colorWhite !important;
}

.main-subtitle {
    color: $colorFlamingo;
    margin-bottom: 3rem;
    font-weight: normal;
    text-align: start;

    @include media('<=phone') {
        width: 100%;
    }
}

.flex {
    display: flex;
    justify-content: space-between;

    @include media('<=phone') {
        display: block;
    }
}

.paymentSelectBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.grid {
    margin-bottom: 2.5rem;
    padding-bottom: 2.5rem;
    flex-direction: row-reverse;

    @include media('<=phone') {
        margin-bottom: 1.5rem;
    }
}

.buttons {
    margin-bottom: 2.5rem;
    padding-bottom: 2.5rem;
    flex-direction: reverse;
    width: 50%;

    @include media('<=phone') {
        width: 90%;
        margin-left: 15px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.submitButtonsMobile {
    display: none;

    @include media('<=phone') {
        padding: 0 1rem;
        display: flex;
        flex-direction: column-reverse;
        position: fixed;
        bottom: 0;
        left: 46%;
        transform: translateX(-50%);
        width: 100%;
        background: #ebebeb;
    }
}

.submitButtonsDesktop {
    display: flex;
    gap: 1rem;

    @include media('<=phone') {
        display: none;
    }
}

.width55 {
    margin-right: 2rem;
    width: 55%;

    @include media('<=phone') {
        width: 100%;
    }
}

.flexColumn {
    display: flex;

    flex-direction: column;
}

.flexColumnReverse {
    display: flex;
    flex-direction: column-reverse;
}

.width33 {
    width: 33%;
    margin-left: 5rem;

    @include media('<=phone') {
        width: auto;
        margin-left: 0;
    }
}

.summary {
    background-color: #ebebeb;
    border-radius: 2rem;
    margin-top: 5rem;
    margin-bottom: 2rem;
    left: calc(-50vw + 50%);
    height: fit-content;
    padding: 2rem;

    @include media('<=phone') {
        position: relative;
        width: 100vw;
        margin-top: 0;
        border-radius: 0;
        margin-top: 0;
        margin-left: 0;
        padding: 20px 20px 123px 20px;
    }
}

.loading {
    height: 7rem;
}

.payment-option {
    margin-bottom: 0;
}

.payment-description {
    margin: 0;
    margin-top: 0.5rem;
    font-size: 1.2rem;
    color: $colorSilverChalice;
}

.payment-logos {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & svg {
        padding-left: 1rem;
    }

    @include media('<=phone') {
        display: block;
    }
}

.satisfied {
    display: flex;
    margin-top: auto;
    border-radius: 1rem;
    color: white;
    background: $colorAtlantis;

    & svg {
        height: auto;
        width: 32%;
        margin-right: 1rem;
    }

    & .satisfied-text {
        display: flex;
        flex-direction: column;
        padding: 1rem 0;

        & .satisfied-title {
            font-size: 1.4rem;
            text-transform: uppercase;
            color: white;
        }
    }
}

.payementTitle {
    font-size: medium;
    font-weight: bold;
}

.flex {
    display: flex;

    @include media('<=phone') {
        display: block;
    }
}

.inline {
    display: inline;
}

.biogaran-cgu-text {
    display: block;
    color: $colorFlamingo;
    cursor: pointer;
    line-height: 1.6 !important;
}

.input {
    border: 1px solid black;
    border-radius: 0%;
}

.menu {
    position: absolute;
    left: 0;
}

.margin {
    margin-top: 7rem;
}

.info-text-div {
    width: 90%;
    margin: 1rem auto;
    text-align: justify;
    text-align-last: center;
    color: #a6a6a6;
}

.promo-input-container {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.promo-div {
    display: flex;
}

.promo-input {
    display: flex;
    width: 35%;
    border: 1px solid #d9d9d9;
    height: 3rem;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    padding-left: 2rem;
    font-size: large;
    margin-bottom: 0 !important;

    &:active,
    &:focus {
        border: 1px solid #d9d9d9;
    }

    @include media('<=phone') {
        width: 85%;
    }
}

.promo-input::placeholder {
    font-size: large;
}

.promo-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    margin: 0;
    font-size: large;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    color: white;
    background-color: #2b2e37;

    &:focus,
    &:active,
    &:visited {
        color: white;
    }

    &:disabled {
        cursor: not-allowed;
        background-color: #2b2e3757;
    }

    @include media('<=phone') {
        width: 15%;
    }
}

.promo-code-error {
    color: $colorRedOrange;
    display: block;
}

.promo-text {
    display: block;
    margin-bottom: 1rem;
}

.biogaran-cgu {
    display: flex;
    align-items: center;
}

.giftCardExplanation {
    margin: 0;
    margin-bottom: 3rem;
}

.infoTextGrey {
    color: #a6a6a6;
    text-align: center;

    @include media('<=phone') {
        padding-left: 1rem;
    }
}

.explanations {
    margin-bottom: 1rem;
    display: flex;
}