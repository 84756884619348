.text {
    text-align: center;
    margin: 3rem 0;
}

.stronger {
    font-family: Lato !important;
    font-weight: bolder;
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.button {
    margin: 0 2rem;
    flex: 1 1 auto;
}
