.menuLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    width: 100%;
}

.iconContainer {
    width: 2rem;
}

.labelContainer {
    display: flex;
    align-items: center;
    font-family: Lato !important;
    font-size: 1.4rem;
    margin-left: 1rem;
    line-height: 1; // Maximum center
}

.dashIcon {
    margin-left: auto;
    transform: rotate(-90deg);
}