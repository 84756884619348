@import '../../../styles/colors';

$iconSize: 1.6rem;
$inputHeight: 5rem;
$inputHeightSmall: 4rem;

.inputContainer {
    position: relative;
}
.input-title {
    font-family: Lato !important;
    font-weight: bold;
    margin-bottom: 0;
}
.input-text {
    margin-bottom: 1.5rem;
    margin-top: 0;
}
.inputField {
    display: block;
    width: 100%;
    background: transparent;
    height: $inputHeight;
    border: 1px solid $colorAlto;
    border-radius: 1rem;
    box-shadow: none;
    font-size: 1.4rem;
    padding: 0 1rem;
    transition: 0.2s;

    &:focus,
    &:hover {
        border: 1px solid $colorSilverChalice;
    }

    &.withError {
        border-color: $colorRedOrange;
    }

    &.withLeftIcon {
        padding-left: 4rem;
    }

    &.withRightIcon {
        padding-right: 4rem;
    }

    &.small {
        height: $inputHeightSmall;
    }
    &.phone {
        border-radius: 1rem;
    }
    &.is-modified {
        width: 130%;
        border: none;
        margin-bottom: 0;
        box-shadow: none;
        &.phone {
            width: 100%;
            &:focus {
                box-shadow: none;
            }
        }
    }
}

.errorMessage {
    display: block;
    color: $colorRedOrange;
    margin-top: 0.5rem;
}

.icon {
    position: absolute;
    height: $iconSize;
    width: $iconSize;
    top: 50%;
    transform: translateY(-50%);
    color: $colorSilverChalice;
}

.leftIcon {
    @extend .icon;

    left: 1.5rem;
}

.rightIcon {
    @extend .icon;

    right: 1.5rem;
}

.label {
    color: $colorDoveGray;
    font-family: Lato !important;
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
}
.searchBarLabel {
    color: black;
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1.4rem;
}
.isSearchbar {
    height: 3.2rem;
    border-radius: 0.5rem;
}
/**
    If textArea prop is passed, it will render <textarea> instead. It has small differences
 */
.textarea {
    resize: vertical;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
