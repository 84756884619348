@import '../../../styles/colors';

.menuList {
    margin: 0;
    padding: 0;
    list-style: none;
}

.menuItem {
    border-bottom: 1px solid $colorAlto;

    &:last-of-type {
        border-bottom: none;
    }
}
