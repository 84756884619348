@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/rwd';

/**
    Consider extending input props instead
 */
.roundButton {
    width: 4rem;
    padding: 0;
}

.locationSection {
    position: relative;
    margin-bottom: 1.5rem;
}

.radiusSection {
    margin-bottom: 1.5rem;
}

.wifi-toggle {
    display: flex;
    margin-bottom: 1rem;
}

.grid {
    display: flex;
    align-items: center;

    &>* {
        margin-right: 1rem;
    }
}

.toggle {
    margin-left: auto;
}

.radiusInput {
    width: 8rem;
    text-align: right;
}

.radiusNativeField {
    padding-right: 2rem;
}

.radiusInputSuffix {
    line-height: 1.6rem;
    color: $colorSilverChalice;
}

.nameSection {
    margin-bottom: 1.5rem;
}

.toggle {
    margin-bottom: 1rem;
}

.buttons {
    margin-top: 2rem;
    display: flex;
    align-items: center;

    @include media('<=phone') {
        flex-wrap: wrap;
    }
}

.saveButton {
    margin-right: 1.5rem;
}

.form-svg-icon {
    margin-right: 1rem;
}

.tooltip {
    vertical-align: middle;
    margin-left: 0.5rem;
}

.title-container {
    display: flex;
}

.wifiZoneItem {
    margin-bottom: 1rem;
}

.title-class {
    font-size: 1.7rem;
    margin: 0.5rem 0 0 0;
}

.gray-text {
    margin: 0.5rem 0 1rem 0;
}

.deleteButtonWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}

.deleteButton {
    font-weight: $fontWeightBold;

    &:hover {
        color: #ed6e18;
    }

    @include media('<=phone') {
        display: block;
        flex: 0 0 100%;
        margin-top: 2rem;
    }
}

.field {
    margin-bottom: 2rem;
}

.widthAuto {
    width: auto;
}

.icon-section {
    display: flex;
    align-items: center;
}

.pencil {
    margin-left: 1rem;
    margin-right: 0.5rem;
    vertical-align: middle;
}

.edit {
    cursor: pointer;

    @include media('<=phone') {
        text-align: center;
    }
}

.add-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 5px;
    border: 1px rgb(221, 221, 221) solid;
    margin: 1rem 0 2rem 0;
}

.submit-button {
    flex: 0 0 0rem;
    margin: 0;
    margin-left: 0px;
    margin-left: 2rem;
    padding: 0;
    font-size: 1.2rem;
    height: 2.2rem !important;
    width: auto;
    background: none !important;
    border: 1px #99ca3b solid !important;
    border-radius: 6px;
    color: #99ca3b !important;
    font-weight: 100;
    padding: 0.4rem 0.6rem 0.4rem 0.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.submit-button .form-svg {
    margin-left: 1rem;
}

.form-option-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px rgb(221, 221, 221) solid;
    padding-bottom: 0.8rem;
    margin: 1rem 0 2rem 0;
}

.registered-info {
    display: flex;
    align-items: center;
}

.add-buttons-container {
    display: flex;

    margin: 1rem 1rem 0 0;
}

.displaySuccessMessage {
    display: none;
    color: #99ca3b;
}

.displayActive {
    display: block;
}

.cancel-button {
    margin: 0;
    margin-left: 2rem;
    padding: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: 1px rgb(161, 161, 161) solid !important;
    color: rgb(161, 161, 161) !important;
    height: 2.2rem !important;
    width: 2.2rem !important;
    background: none !important;
}

.edit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-left: 1rem;
    padding: 0;
    background: none !important;
    height: 2.2rem !important;
    width: 2.2rem;
    color: $colorFlamingo !important;
    border: 1px $colorFlamingo solid !important;
    border-radius: 6px;
}

.editIconTypeContainer {
    display: flex;
}

.typeModifier {
    margin-left: 5rem;
}

.icon {
    width: 40px;
    height: 40px;
}

.button {
    min-width: 19rem;
}