@import '../../../styles/rwd';

.headline {
    margin-bottom: 3rem;

    @include phoneHidden();
}

.radio {
    margin-bottom: 1.5rem;
    display: block;
}

.reset {
    margin-left: 1.5rem;

    @include media('<=phone') {
        margin-left: 0;
        margin-top: 2rem;
        width: 100%;
        display: block;
    }
}

.submit {
    @include media('<=phone') {
        display: block;
        width: 100%;
    }
}
