@import '../../../../styles/rwd';

.control {
    margin-bottom: 1.5rem;
}

.submit {
    @include media('<=phone') {
        display: block;
        width: 100%;
    }
}
