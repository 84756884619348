@import '../../../styles/rwd';
@import '../../../styles/colors';


.overflow {
    height: calc(100vh - 14rem); // 6rem (the height of the menu) + 4rem x 2 (padding)
    overflow: auto;
    padding-left: 3rem;
}

.colorWarning {
    color: red
}

.list {
    max-width: 36rem;
}

.headline {
    margin-bottom: 3rem;

    @include phoneHidden();
}

.margin3rem {
    margin: 3rem 3rem;

    @include media('<=phone') {
        margin: 3rem 0;
    }
}

.dashIcon {
    transform: rotate(90deg);
    margin-right: 0.5rem;
}

.alignCenter {
    align-items: center;
}

.webkit-inline {
    margin: 0 5rem;
    display: -webkit-inline-box;
    color: $colorFlamingo;
    -webkit-box-align: center;

    @include media('<=phone') {
        margin: 0 2rem;
    }
}

.flexBlock {
    display: flex;
}

.width50 {
    width: 50%;
    box-shadow: 0 0 0rem #eee;
    @include media('<=phone') {
        width: 100%;
    }
}

.marginLeft {
    margin-left: 3rem;

    @include media('<=phone') {
        margin-left: 0;
    }
}

.marginRight {
    margin-right: 3rem;

    @include media('<=phone') {
        margin-right: 1rem;
    }
}

.marginTop {
    margin-top: 1rem;
}

.marginBottom {
    margin-bottom: 2rem;
}

.buttonModify {
    width: 50%;
    color: #ED6E18 !important;
    background: #ED6E1820;
    box-shadow: inset 2 2 2 2 black;


    @include media('<=phone') {
        width: 100%;
    }
}

.subheadline {
    margin-bottom: 2rem;

    @include media('<=phone') {
        margin-left: 0;
    }
}

.paymentmeanwarning {
    font-style: italic;
}
