@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.headline {
    margin-top: 0rem;
    margin-bottom: 1rem;
    text-align: start;

    // Just make words wrap to next line
    @include media('<=phone') {
        width: 100%;
    }
}

.headlineBiogaran {
    margin-top: 0rem;
    margin-bottom: 1rem;

    @include media('<=phone') {
        padding: 0;
    }
}

.subHeadline {
    text-align: start;
    margin-top: 0rem;
    margin-bottom: 1.5rem;

    @include media('<=phone') {
        width: 100%;
    }
}

.container {
    max-width: 100%;
    padding-left: 12%;
    padding-right: 12%;
    margin: 0;

    @include media('<=phone') {
        margin: auto;
        padding-left: 5%;
        padding-right: 5%;
    }
}

.buttonsBioggy {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}

.grid {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
    height: 30rem;

    & > * {
        flex: 0 0 calc(32% - 1rem);
    }

    @include media('<=tablet') {
        flex-wrap: wrap;
        justify-content: space-between;
        height: auto;

        & > * {
            flex: 0 0 calc(50% - 1rem);
            margin: 0 0 1rem 0;
        }
    }

    @include media('<=phone') {
        & > * {
            flex: 0 0 100%;
            margin-bottom: 2.5rem;
        }
    }
}

.flex {
    display: flex;
    justify-content: space-between;

    @include media('<=phone') {
        display: block;
    }
}

.optionsGrid {
    padding-right: 2rem;
    margin-bottom: 3rem;

    @include media('<=tablet') {
        flex-wrap: wrap;
        justify-content: space-between;
        height: auto;

        & > * {
            flex: 0 0 calc(50% - 1rem);
            margin: 0 0 1rem 0;
        }
    }

    @include media('<=phone') {
        flex: 0 0 100%;
        padding-right: 0;
        margin-top: 0;
        width: 100%;
        margin-bottom: 2.5rem;
    }
}

.hint-text {
    font-size: 1.2rem;
    color: $colorSilverChalice;
    margin: 0 0 1.5rem;
}

.button {
    padding: 1rem;
}

.buttons {
    margin-top: 2rem;
    flex-direction: row-reverse;

    @include media('<=phone') {
        padding: 1rem;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
        max-width: 100%;
        display: flex;
        flex-direction: column;
    }
}

.submitButtonsMobile {
    display: none;
    margin-bottom: 0;

    @include media('<=phone') {
        display: flex;
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        background-color: #ebebeb;
    }
}

.submitButtonsDesktop {
    display: flex;
    gap: 1rem;

    @include media('<=phone') {
        display: none;
    }
}

.border {
    border-radius: 12px;
    border: 1px solid #99ca3a;
    padding: 5rem;

    @include media('<=phone') {
        padding: 2rem;
    }
}

.info {
    float: right;
}

.width35 {
    @include media('<=phone') {
        width: 35rem !important;
    }
}

.buttonOnGoForward {
    border: none !important;
    pointer-events: cursor;
}

.infoTextGrey {
    color: #a6a6a6;

    @include media('<=phone') {
        padding-left: 1rem;
    }
}

.top-row {
    display: flex;
    align-items: center;
    padding-bottom: 0rem;
    padding-top: 0rem;

    @include media('<=phone') {
        margin-left: 0;
    }
}

.summaryBlock {
    background-color: #ebebeb;
    width: 30%;
    border-radius: 2rem;
    margin-top: 5rem;
    margin-left: 5rem;
    left: calc(-50vw + 50%);
    height: fit-content;
    padding: 2rem;

    @include media('<=phone') {
        position: relative;
        width: 100vw;
        border-radius: 0;
        border-radius: 0;
        margin-left: 0;
        margin-top: 0;
        padding: 20px 20px 157px 20px;
    }

    @include media('<tablet') {
        position: relative;
        width: 100vw;
        border-radius: 0;
        border-radius: 0;
        margin-left: 0;
        margin-top: 0;
        padding: 20px 20px 157px 20px;
    }
}

.summaryTextBlock {
    display: flex;
    justify-content: space-between;
}
