@import '../../../styles/colors';

.wifiZone {
    display: flex;
    transform: translate(
        -50%,
        -50%
    ); // required for every google-map-react markers.
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: solid #56ccf2 1px;
    z-index: 1200;
}
.wifiZone-center {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #56ccf2;
}
