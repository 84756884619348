@import '../../../styles/rwd';

.wrapper {
    max-width: 36rem;
    @include media('<=phone') {
        margin: 0 auto;
    }
}

.headline {
    margin-bottom: 3rem;

    @include phoneHidden();
}
