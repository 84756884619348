.checkbox {
    margin-top: 1rem;
    display: block;
}

.save {
    width: 20rem;
    margin-top: 3rem;
}
.form {
    max-width: 44rem;
}
