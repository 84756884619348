@import '../../../../styles/colors';

.list {
    position: relative;
}

.row {
    display: flex;
    margin-bottom: 1rem;
}

.icon {
    margin-right: 2rem;
    flex: 0 0 4rem;
    background: $colorWhite;
}

.item {
    flex: 1 1 auto;
}

.timeline {
    width: 0.6rem;
    position: absolute;
    top: 0;
    bottom: 0;
    left: (2rem - 0.3rem);
    background: $colorAlto;
    border-radius: 0.3rem;
}
