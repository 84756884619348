@import '../../../../styles/colors';

.icon {
    width: 1.6rem;
    height: 1.6rem;
    color: inherit;
    vertical-align: middle;
}

.IconWarning {
    margin-left: 1rem;
}

.link {
    color: $colorDoveGray;
    display: flex;
    align-items: center;

    &:focus {
        color: $colorFlamingo;
    }
}

.active {
    color: $colorFlamingo;
}

.menuItem {
    display: block;
}

.mobileWrapper {
    border-radius: 4px;
    border: 1px solid $colorAlto;
    overflow: hidden;
}

.mobilePanelWrapper {
    padding: 0.5rem 1rem;
    position: relative;
}

.mobileContentWrapper {
    padding: 0 1rem;
    border-top: 1px solid $colorAlto;
}

.mobileDashIcon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
}

.iconRotated {
    transform: translateY(-50%) rotate(180deg);
}