@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.root {
    background: $colorWhite;
    padding: 1.5rem;
    border-radius: 1rem;

    & > *:not(:first-child) {
        margin-top: 1rem;
    }

    & .title {
        font-family: Lato !important;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
    }

    & .layers-group {
        display: flex;

        & *:not(:last-of-type) {
            margin-right: 3rem;
        }

        @include media('<=phone') {
            flex-wrap: wrap;
            & *:not(:last-of-type) {
                margin-right: 1rem;
            }
        }
        .radio_mask {
            display: none;
        }
        .radio {
            flex: 1;
            img {
                height: 8rem;
                width: auto;
                margin-bottom: 1rem;
                border-radius: 35%;
            }
            text-align: center;

            &.checked {
                img {
                    border-style: none;
                    border: 3px solid $colorFlamingo;
                }
            }
        }
    }
}
.gray {
    filter: grayscale(100%);
    color: grey;
}
.unlockMessage {
    display: block;
    color: $colorFlamingo;
    font-size: 1.3rem;
    text-transform: none;
    font-weight: normal;
}
.icon {
    margin-left: 1rem;
}
