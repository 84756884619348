@import '../../../styles/colors';
@import '../../../styles/rwd';

.headline {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}
.reasons {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    gap: 0.5rem 1rem;

    @include media('<=phone') {
        grid-template-columns: repeat(2, 50%);
    }

    & button:nth-last-child(1):nth-child(odd) {
        grid-column: 2;
    }
}
.textarea {
    height: 10rem;
    margin: 2rem 0;
}
.footer-buttons {
    display: flex;
    margin-top: 5rem;
    justify-content: space-between;
}
.highlighted {
    color: $colorFlamingo;
}
.form-title {
    font-family: Lato !important;
    font-weight: bold;
}
.submit-button {
    &:enabled {
        // not disabled
        background-color: $colorFlamingo;
        color: white;
    }
}
.button {
    height: initial;
    padding: 2rem;
    word-break: break-word;
}
