@import '../../../styles/colors';

@mixin selected {
    border: 1px solid $colorFlamingo;
    color: $colorFlamingo;
}

.list {
    display: flex;
}

.button {
    margin-right: 1.5rem;
    border-radius: 1rem;
    border: 1px solid $colorAlto;
    height: 4rem;
    padding: 0;
    width: 4.5rem;
    flex: 0 0 4.5rem;
    font-size: 1.4rem;
    transition: 0.2s;

    &:active,
    &:focus,
    &:hover {
        @include selected;
    }
}

.selected {
    @include selected;
}
