@import '../../../styles/colors';

.wrapper {
    display: block;
    cursor: pointer;
}

.nativeField {
    display: none;

    &:checked {
        & + .customField {
            border-color: $colorChristi;
            &.error {
                border-color: $colorRedOrange;
                &::after {
                    background: $colorRedOrange;
                }
            }
            &::after {
                background: $colorChristi;
                transform: translateY(-50%) translateX(1.6rem);
            }
        }
    }
}

.nativeFieldWifi {
    display: none;
    
    &:checked {
        & + .customField {
            border-color: $colorFlamingo;
            &.error {
                border-color: $colorRedOrange;
                &::after {
                    background: $colorRedOrange;
                }
            }
            &::after {
                background: $colorFlamingo;
                transform: translateY(-50%) translateX(1.6rem);
            }
        }
    }
}

.nativeFieldWifi, .nativeField {
    &:checked:disabled {
        &+.customField {
            border-color: $colorDisabledGray;
            &::after {
                background: $colorDisabledGray;
            }
        }
    }
}

.customField {
    vertical-align: middle;
    height: 2rem;
    width: 3.6rem;
    border-radius: 1rem;
    border: 1px solid $colorAlto;
    display: inline-block;
    position: relative;

    &::after {
        transition: 0.2s;
        content: '';
        width: 1.4rem;
        height: 1.4rem;
        position: absolute;
        top: 50%;
        left: 0.2rem;
        transform: translateY(-50%);
        background: $colorAlto;
        border-radius: 1rem;
    }
}

.label, .labelDisabled {
    user-select: none;
    margin-left: 0.8rem;
    display: inline-block;
    vertical-align: middle;
}

.labelDisabled {
    color: $colorDisabledGray;
}