@import '../../../styles/colors';

.collapse {
    border: 1px solid $colorAlto;
    border-radius: 5px;
    padding: 0 1rem;
}

.panel {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.panelContainer {
    flex: 1 1 auto;
    margin-right: 2rem;
}

.dash {
    color: $colorSilverChalice;

    &.rotated {
        transform: rotate(180deg);
    }
}

.content {
    display: none;

    padding: 1rem 0;

    &.expanded {
        display: block;
        border-top: 1px solid $colorAlto;
    }
}
