@import '../../../styles/layout';
@import '../../../styles/rwd';
@import '../../../styles/colors';

.nav-header {
    height: 6rem;
    box-shadow: 0 2px 10px 0 rgba($colorCodGray, 0.15);
    position: relative;
    z-index: 1101;

    @include media('<=tablet') {
        height: 11rem;
        padding-top: 1.5rem;
    }

    @include media('<=phone') {
        top: 0;
        position: fixed;
        height: 6rem;
        z-index: 1101;
        width: 100%;
        background-color: white;
    }
}

.container {
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media('<=tablet') {
        display: block;
    }

    @include media('<=phone') {
        border-bottom: 1px solid $colorAlto;
    }
}

.logo-container {
    width: $sidebarWidth; // Matches sidebar and center it

    @include media('<=tablet') {
        margin: 0 auto 3rem;
    }
}

.logo {
    width: 15rem;
    margin: 0 auto;
}

.nav {
    height: 100%;
    margin-right: auto;
    display: flex;
    align-items: center;
    z-index: 15;

    &>* {
        margin-right: 3rem;

        &:last-of-type {
            margin-right: 0;
        }
    }

    @include media('<=tablet') {
        justify-content: center;
        height: auto;
    }

    @include media('<=phone') {
        position: fixed;
        top: 6rem;
        bottom: 0;
        background: $colorWhite;
        left: 0;
        width: 100%;
        display: none;
        padding: 0 2rem 0;

        &.mobile-nav-open {
            display: block;
        }

        &>* {
            height: 5rem;
            line-height: 5rem;
            border-bottom: 1px solid $colorAlto;
            margin: 0;
            display: block;
            width: 100%;
            padding: 0;
        }
    }
}

.user-area {
    margin-right: 3rem;

    @include media('<=tablet') {
        position: absolute;
        top: 2rem;
        right: 2rem;
        margin-right: 0;
    }

    /**
        In mobile view there is user area in burger nav
     */
    @include media('<=phone') {
        display: none;
    }
}

.user-area-mobile {
    display: none;

    @include media('<=phone') {
        display: block;
    }
}

.burger-icon {
    position: absolute;
    right: 1.5rem;
    height: 2.4rem;
    width: 2.4rem;
    top: 50%;
    margin-top: -1.2rem;
    cursor: pointer;
    display: none;

    @include media('<=phone') {
        display: block;
    }
}

.notifications {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
}

.logo-header {
    position: absolute;
    right: 18rem;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        @include media('<=whitelabeltext') {
            display: none;
        }
    }

    img {
        max-height: 6rem;
        max-width: calc($sidebarWidth / 2);
        margin-left: 1rem;

        @include media('<=whitelabelimg') {
            display: none;
        }
    }
}