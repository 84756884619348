@import '../../../../styles/colors';
@import '../../../../styles/rwd';
@import '../../../../styles/fonts';

.header-nav-link {
    color: $colorCodGray;
    font-weight: $fontWeightBold;
    font-size: 1.4rem;
    padding: 0 1rem;
    line-height: 6rem;

    &.active {
        color: $colorFlamingo;
        position: relative;

        &::after {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 2px;
            width: 100%;
            content: '';
            background: $colorFlamingo;

            @include media('<=tablet') {
                bottom: 4px;
            }

            @include media('<=phone') {
                bottom: -1px;
            }
        }
    }

    @include media('<=tablet') {
        line-height: 4rem;
    }
}

.flexGrid {
    display: flex;
    align-items: center;

    @include media('<=tablet') {
        line-height: 4rem;

    }
}

.active {
    color: $colorFlamingo;
    position: relative;
}