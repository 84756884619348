@import '../../../../styles/colors';
@import '../../../../styles/fonts';
@import '../../../../styles/rwd';

.icon {
    border-radius: 50%;
    border: 1px solid white;
}

.no-gps {
    z-index: 601 !important;
}

.google-marker-container {
    transform: translate(-50%, -50%);
    position: relative;
    z-index: 1;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 2px solid white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px 1px grey;
}

.google-marker {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
