@import '../../../../styles/colors';
@import '../../../../styles/fonts';
@import '../../../../styles/rwd';

.container {
    display: flex;
    display: contents;
    align-items: center;
    padding: 1rem;
}

.containerBiogaran {
    display: block;
    align-items: center;
    padding: 1.5rem;
    min-height: 5.5rem;
}

.container-biogaran-paddingLess {
    display: block;
    align-items: center;
    padding: 1.5rem;
    min-height: 5.5rem;

    @include media('<=phone') {
        padding: 0;
    }
}

.period-container {
    width: 75%;

    @include media('<=phone') {
        width: 50%;
    }
}

.period-container-biogaran {
    margin: auto;
    white-space: nowrap;
}

.flex-manage {
    display: flex;

    @include media('<=phone') {
        display: block;
    }
}

.flex {
    display: flex;
    justify-content: space-evenly;
}

.flex-center {
    display: flex;
    align-items: center;
}

.subscriptionPeriodOption {
    display: flex;
    width: 60%;
}

.list-info {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    padding-top: 1rem;
    word-wrap: break-word;
    list-style-type: none;

    @include media('<=phone') {
        padding-top: 0.5rem;
        font-size: 1.3rem;
        padding-left: 0;
    }
}

.price-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;

    @include media('<=phone') {
        width: 50%;
    }
}

.price-container-biogaran {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 3rem;
    align-items: end;

    @include media('<=phone') {
        align-items: center;
        padding-left: 3rem;
    }
}

.price-container-biogaran-manage {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 3rem;
    align-items: end;

    @include media('<=phone') {
        align-items: start;
        padding-left: 0;
    }
}

.padding-top {
    padding-top: 1rem;

    @include media('<=phone') {
        padding-top: 0;
    }
}

.price-container-biogaran-manage {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: end;

    @include media('<=phone') {
        align-items: start;
    }
}

.period-container-biogaran-margin-none {
    white-space: nowrap;
}

.period-time {
    font-size: 2rem;
    font-weight: bold;
    width: max-content;

    @include media('<=phone') {
        font-size: 1.5rem;
    }
}

.subtext {
    font-size: 1rem;
    margin-top: 0.5rem;
}

.price-value {
    font-weight: $fontWeightBold;
    font-size: 2rem;
    margin-right: 0.5rem;
    margin-bottom: 1rem;

    @include media('<=phone') {
        margin-right: 0;
        margin-bottom: 0;
        font-size: 1.5rem;
    }
}

.monthly {
    padding-left: 0.5rem;
    font-size: 1.3rem;
}

.promo-text {
    color: $colorChristi;
    font-weight: $fontWeightBold;

    @include media('<=phone') {
        position: absolute;
    }
}

.offert {
    color: white;
    position: absolute;
    max-width: 5rem !important;
    top: 1rem;
    right: 0;
    font-size: small;
    transform: rotate(45deg);
}

.promo-text-biogaran {
    color: $colorChristi;
    font-weight: $fontWeightBold;

    @include media('<=phone') {
        position: absolute;
        left: 3rem;
        bottom: 22.5rem;
    }
}

.promo-saving {
    color: #99d529;
    font-weight: $fontWeightBold;
    font-size: 1.3rem;
}

.commitment-text {
    font-size: 1.3rem;
    color: $colorSilverChalice;
    margin-top: 1rem;
    padding-left: 2rem;
    text-align: right;

    @include media('<=phone') {
        margin-top: 0;
        text-align: left;
        font-size: 1rem;
        padding-left: 2rem;
    }
}

.commitment-text-manage {
    font-size: 1.3rem;
    color: $colorSilverChalice;
    margin-top: 1rem;
    padding-left: 2rem;
    text-align: right;

    @include media('<=phone') {
        margin-top: 0;
        padding-left: 0;
        text-align: left;
        font-size: 1.3rem;
    }
}
