@import '../../../../styles/colors';

.button {
    width: 4rem;
    height: 4rem;
    box-shadow: 3px 3px 2rem 0 rgba($colorCodGray, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: $colorWhite;
    color: $colorOsloGray;
    transition: 0.2s;

    &:active,
    &:focus {
        color: $colorOsloGray;
        box-shadow: 3px 3px 2rem 0 rgba($colorCodGray, 0.3);
    }

    &:hover {
        box-shadow: 2px 2px 1.5rem 0 rgba($colorCodGray, 0.5);

        .description {
            position: absolute;
            display: block;
            width: auto;
            right: 5rem;
        }
    }

    & > svg {
        width: 2rem;
        height: 2rem;
    }
}
.actionButton {
    @extend .button;
    background: linear-gradient(
            225.17deg,
            #e04037 0%,
            #e96039 29.69%,
            #eb6e29 61.46%,
            #f2942f 100%
        )
        var(--x, 0) / 200%;
    color: $colorWhite;
    &:hover {
        --x: 100%;
    }
}
.disabledButton {
    @extend .button;
    background: #a6a6a6;
    color: $colorWhite;
    &:active,
    &:focus {
        background: #a6a6a6;
        color: $colorWhite;
    }
}
.active {
    color: $colorFlamingo;

    &:active,
    &:focus {
        color: $colorFlamingo;
    }
}

.biggerIcon > svg {
    width: 2.5rem;
    height: 2.5rem;
}

.description {
    color: initial;
    display: none;
    width: 0;
    background-color: white;
    box-shadow: 2px 2px 1.5rem 0 rgba($colorCodGray, 0.3);
    padding: 0.1rem 1rem;
    border-radius: 0.5rem;
    white-space: nowrap;
}
