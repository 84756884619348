/**
    Add more variations if other containers are introduced
 */
$standard-container: 75rem;
// Invisible on desktop but exists on mobile
$padding: 2rem;

.container {
    max-width: $standard-container + $padding * 2;
    margin: 0 auto;
    padding: 0 $padding;
}
