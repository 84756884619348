.buttonsredirect {
    margin-bottom: 2.5rem;
    margin-top: 2.5rem;
}

.center {
    text-align: center;
}

.proposal {
    margin-top: 3rem;
}

.line {
    text-align: center;
    margin: 3rem 0;
}
