@import '../../../../styles/fonts';
@import '../../../../styles/colors';

.tracker-actions {
    display: flex;
    flex-direction: column;

    & .floating {
        margin: 0.2rem 0;
    }
}

.action {
    cursor: pointer;
    flex: 0 0 auto;
    margin-right: 1rem;
}

.icon {
    color: $colorWhite;
    width: 2rem;
    height: 2rem;
}
