@import '../../../../styles/colors';

.container {
}

.headline {
    margin-bottom: 3rem;
}

.form-container {
    max-width: 36rem;
    margin: 8rem auto 0;
}

.subtext {
    text-align: center;
    margin-bottom: 3rem;
    line-height: 1.4;
    color: $colorDoveGray;

    a {
        display: block;
    }
}

.back-button {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
