.marker-tooltip {
    padding: 1rem;

    th {
        text-align: left;
    }

    th.name {
        font-size: 1.5rem;
    }

    span {
        display: block;
    }

    p {
        display: inline-block;
        color: inherit;
    }
}

.event-icon {
    width: 20px;
    height: auto;
    position: relative;
    z-index: 1;
    top: -10px; // - (width / 2)
    left: -10px; // - (width / 2)
    transform: translate(
        -50%,
        -50%
    ); // required for every google-map-react markers.
}

.event-tooltip {
    display: none;
    position: relative;
    z-index: 2;

    > * {
        font: 12px/1.5 'Helvetica Neue', Arial, Helvetica, sans-serif;
        opacity: 0.9;
        padding: 1rem;
        margin-left: 6px;
        position: absolute;
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 3px;
        color: #222;
        white-space: nowrap;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: none;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);

        & th {
            font-family: Lato !important;
            font-weight: bold;
            text-align: left;
        }
    }
}

.custom-marker {
    cursor: pointer;
    width: 20px;
    height: auto;
    position: relative;
    z-index: 600;

    &:hover {
        .event-tooltip {
            display: flex;
        }
    }
}
