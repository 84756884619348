@import '../../../styles/rwd';

.container {
    width: 36rem;
    margin: 2rem auto;
    @include media('<=phone') {
        margin: 8rem auto 0;
    }
    padding: 0 1.5rem;
    background-color: white;
}

.primary-headline {
    margin: 3rem 0;
}

.secondary-headline {
    margin-bottom: 1.5rem;
}

.form {
    margin-bottom: 0.5rem;
}

.terms-link {
    margin-top: 3rem;
}
.forgot-password {
    margin-bottom: 3rem;
}
