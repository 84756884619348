@import '../../../../styles/colors';

.list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin: 0 auto;
}

.item {
    padding: 1.5rem 1.5rem 1.5rem 0;
    border-bottom: 1px solid $colorAlto;

    &:last-of-type {
        border-bottom: none;
    }
}

.paymentmeanwarning {
    font-style: italic;
}
