@import '../../../styles/colors';
@import '../../../styles/rwd';

.container {
}

.top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $colorAlto;
    padding: 0.5rem 0;
}

.plan-name {
    font-size: 1.4rem;
}

.active-indicator {
    margin-left: 1rem;
}

.price-or-expire {
    margin-left: auto;
    font-size: 1.4rem;
}

.info-text {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
}

.buttons-container {
    display: flex;
    justify-content: space-around;

    @include media('<=tablet') {
        flex-direction: column;
    }
}

.manage-button {
}
