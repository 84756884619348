@import '../../../../styles/colors';

.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.input {
    display: none;
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;

    input[type='file'] {
        display: none;
    }
}

.item {
    position: relative;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 0 3px $colorWhite, 0 0 0 5px $colorAlto;
    }

    &.active {
        box-shadow: 0 0 0 3px $colorWhite, 0 0 0 5px $colorFlamingo;

        &:hover {
            box-shadow: 0 0 0 3px $colorWhite, 0 0 0 5px $colorFlamingo;
        }
    }
}
