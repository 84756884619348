@import '../../../styles/layout';
@import '../../../styles/rwd';
@import '../../../styles/colors';

.simple-header {
    height: 6rem;
    box-shadow: 0 2px 10px 0 rgba($colorCodGray, 0.15);
    position: relative;
    top: 0;
    z-index: 5;
    width: 100%;
    @include media('<=phone') {
        position: sticky;
    }
}

.container {
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.logo-container {
    width: 15rem;
    flex: 0 0 15rem;
    align-self: center;
}

.logo {
    height: 5rem;
}

.left-slot {
    position: absolute;
    left: 0;
    margin-left: 3rem;
}

.right-slot {
    position: absolute;
    right: 0;
    margin-right: 3rem;
}

.logout-button {
    @include media('<=phone') {
        display: none;
    }
}

.notifications {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
}
