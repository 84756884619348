.form {
}

.input {
    margin-bottom: 1.5rem;
}

.submit-button {
    margin-top: 4rem;
}
