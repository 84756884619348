.control {
    margin-bottom: 1rem;
}

.alreadyUsedMail input {
    border-color: #ff4733;
}

.checkbox-control {
    display: block;
    margin-bottom: 1rem;
}

.selects-grid {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    & > *:first-of-type {
        flex: 1 1 auto;
    }

    & > *:last-of-type {
        width: 7rem;
        flex: 0 0 7rem;
        margin-left: 2.5rem;
    }
}
.address-grid {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    & > *:first-of-type {
        flex: 1 1 auto;
    }

    & > *:last-of-type {
        width: 12rem;
        flex: 30rem 0 0;
        margin-left: 2.5rem;
    }
}
.checkboxes {
    margin-bottom: 2.5rem;
}

.password-warning {
    overflow: hidden;
    line-height: 0;
    padding: 0;
    margin: 0;
    color: transparent;
}
.password-warning-displayed {
    overflow: hidden;
    transition: all 0.5s;
    background: #fff3cd;
    color: #856404;
    padding: 1rem;
    line-height: 2rem;
    margin-bottom: 1rem;
}
