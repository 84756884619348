/*
    Names generated with
    http://chir.ag/projects/name-that-color/
 */

:root {
    --default: #ed6e18;
    --buttonc1: #f2942f;
    --buttonc2: #e83c38;
    --grenadier: #cc4101;
    --secondary: #fef1ee;
    --green: #99ca3b;
}
:root[data-theme='biogaran'] {
    --default: #08458d;
    --button: #08458d;
    --buttonc1: #08458d;
    --buttonc2: #08458d;
    --grenadier: #284d77;
    --secondary: #bde2e8;
    --green: #afcb5b;
}

$colorFlamingo: var(--default);
$colorGrenadier: var(--grenadier);
$colorBridesmaid: var(--secondary);
$colorAtlantis: var(--green);
$colorChristi: var(--green);
$buttonFlamingo: var(--button);
$buttonC1: var(--buttonc1);
$buttonC2: var(--buttonc2);
$colorAlto: #d9d9d9;
$colorButtonDisabled: #586066;
$colorAzure: #385e9d;
$colorCodGray: #0d0d0d;
$colorDisabledGray: #979797;
$colorMuteGray: #7B7B7B;
$colorDoveGray: #737373;
$colorGoldTips: #eab313;
$colorOsloGray: #898d8e;
$colorPictonBlue: #58c4ea;
$colorRedOrange: #ff4733;
$colorSilverChalice: #a6a6a6;
$colorTawnyPort: #6b2c3f;
$colorWhite: #ffffff;
$colorWildSand: #f4f4f4;
$colorGallery: #ebebeb;
