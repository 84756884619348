@import '../../styles/rwd';
@import '../../styles/colors';

.container {
    width: 100%;
    height: 100vh;
    background: linear-gradient(90deg, #f2942f, #e83c38 51%, #f2942f) var(--x, 0)/200%;
}

.text {
    position: absolute;
    top: 35%;
    padding: 0 40%;

    @include media('<=phone') {
        padding: 5% 15%;
    }

}

.textCenter {
    text-align: -webkit-center;
}

.buttonBlock {
    margin-top: 2rem;
    display: grid;
}