@import '../../styles/colors';
@import '../../styles/rwd';
@import '../../styles/colors';
@import '../../styles/fonts';

.arrowLeft {
    margin-right: 1rem;
    cursor: pointer;
    transform: rotate(90deg);
}

.backgroundContainer {
    background-color: #f2f2f2;
}

.buttons {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 4rem;
}

.buttonMorph {
    @extend .buttonBlock;
    width: 85%;
    padding: 7rem 2rem 0 2rem;
}

.buttonBlock {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    height: -webkit-fill-available;
    justify-content: end;

    @include media('<=phone') {
        width: 100%;
        bottom: calc(13.5vh - (68px + 10px));
        height: auto;
        padding-bottom: 1rem
    }
}

.buttonBlockNotFixed {
    @extend .buttonBlock;

    @include media('<=phone') {
        position: relative;
        margin: auto;
        align-items: center;
    }
}

.buttonBlockModal {
    @extend .buttonBlock;
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    justify-content: end;

    @include media('<=phone') {
        position: inherit;
    }
}

.buttonBlockSummary {
    width: 80%;
    display: flex;
    justify-content: center;

    @include media('<=phone') {
        padding: 0 1rem;
        display: flex;
        position: fixed;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 100%;
        background: #ebebeb;
    }

    margin: auto;
}

.raceContainer {
    justify-content: center;
}

.buttonWidth {
    width: 100%;
}

.checkbox {
    margin-top: 1rem;
    padding-left: 5%;
}

.checkboxIdentification {
    margin-top: 1rem;
    padding-left: 5%;
    display: flex;
}

.commonText {
    text-align: left;
    line-height: 130%;
    font-family: Lato !important;
    font-weight: 400;
    size: 14px;
}

.commonTextSubTitle {
    @extend .commonText;
    margin-bottom: 0.5rem;
    color: #586066;
}

.container {
    display: flex;
    margin: auto;
    height: 90vh;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    max-width: 100rem;
    row-gap: 2rem;
    width: 1024px;
    flex-direction: column;

    @include media('<=phone') {
        width: 85%;
        row-gap: 2rem;
        justify-content: unset;
        height: auto;
        padding: 40px 0 100px 0;
    }
}

.containerBlockHided {
    transition: transform 1000ms ease-in-out;
    overflow: hidden;
    opacity: 0;
}

.containerBlockVisible {
    margin-top: 1rem;
    transition-duration: 2s;
    transition-timing-function: linear;
    transition: transform 2s;
    overflow: visible;
    opacity: 1;
    width: 100%;
}

.deleteButton {
    color: #ed6e18;
    cursor: pointer;
}

.grid {
    display: flex;
    justify-content: space-around;
    width: 100%;

    @include media('<=phone') {
        width: 95%;
        justify-content: space-between;
        padding-left: 5%;
        padding-right: 5%;
        align-items: none;
    }

    align-items: center;
}

.collapse {
    margin: 1.5rem;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 0 0.5rem;
}

.collapseContainer {
    flex: 1 1 auto;
    text-align: left;
    margin-right: 2rem;
    display: flex;
    vertical-align: middle;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem;
}

.column {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 70%;

    @include media('<=phone') {
        width: 100%;
        align-items: none;
        flex-flow: column;
        gap: 2rem;
    }
}

.dashForwardIcon {
    position: relative;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    color: $colorFlamingo;
    width: 1rem;
    height: 1.2rem;
    cursor: pointer;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
}

.gridIdentification {
    margin: auto;
    display: flex;
    justify-content: space-between;

    @include media('<=phone') {
        width: 95%;

        padding-left: 5%;
        padding-right: 5%;
        align-items: none;
    }

    align-items: center;
    width: 70%;
}

.gridDate {
    display: flex;
    justify-content: space-between;
    padding-left: 5%;
    margin: auto;
    padding-right: 5%;
    align-items: center;
    width: 95%;

    @include media('<=phone') {
        width: 100%;
        align-items: unset;
        margin: 0;
        text-align: left;
    }
}

.headline {
    color: #fc4c02;
    margin: 0;
    display: block;
}

.iconOrange {
    color: $colorFlamingo;
    cursor: pointer;
}

.icon {
    width: 49px;
    height: 49px;
}

.iconMorph {
    width: 65px;
    height: 65px;
}

.imgContainer {
    border: 1px solid #d9d9d9;
    width: 15rem;
    height: 15vh;
    padding: 3rem;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    border-radius: 2rem;
    cursor: pointer;

    @include media('<=phone') {
        height: 18vh;
        width: 45%;
        padding: 1.5rem;
        display: block;
    }
}

.imgContainerSelected {
    @extend .imgContainer;
    background-color: #fdf0e8;
    border: 1px solid #ed6e18;
}

.inputBlock {
    width: 100%;
    background: transparent;
    margin-bottom: 0 !important;
}

.input-icons p {
    position: absolute;
}

.inputContainer {
    display: flex;
    border: 1px solid #d9d9d9;
    border-radius: 1rem;
    box-shadow: none;
    font-size: 1.4rem;
    padding: 0 1rem;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    height: 5rem;

    &:focus,
    &:hover {
        border: 1px solid $colorSilverChalice;
    }

    &.withRightIcon {
        padding-right: 4rem;
    }
}

.marginBottom10 {
    margin-bottom: 10%;

    @include media('<=phone') {
        margin-bottom: 5%;
    }
}

.marginTop10 {
    margin-top: 10%;
}

.morphImgContainer {
    @extend .imgContainer;

    @include media('<=phone') {
        width: auto;
        height: auto;
    }

    height: 18vh;
    width: 18rem;
}

.morphImgContainerSelected {
    @extend .imgContainerSelected;

    @include media('<=phone') {
        width: auto;
        height: auto;
    }

    height: 18vh;
    width: 18rem;
}

input[type='checkbox'] {
    accent-color: $colorFlamingo;
}

.margin1 {
    margin: 1rem;
}

.margin2rem {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
}

.magrinAuto {
    margin: auto;
    text-align: center;
}

.nameInput {
    input {
        font-size: 0.8em;
        text-align: center;
        font-family: Arial, sans-serif;
    }

    height: 5rem;
    font-size: 1.6em;
    border-radius: 1rem;
}

.padding5 {
    padding-right: 5%;
    padding-left: 5%;
}

.closeIcon {
    padding-right: 2rem;
    cursor: pointer;
}

.raceInput {
    input {
        font-size: 0.8em;
        text-align: center;
        font-family: Arial, sans-serif;
    }

    width: 40rem;
    height: 4rem;
    font-size: 1.6em;
    margin-top: 3rem;
}

.stepContainer {
    align-items: center;
    color: $colorFlamingo;
    text-align: left;
    padding-left: 2rem;
    display: flex;
}

.subText {
    color: #9f9f9f;
    font-family: Lato;
    font-size: 12px;
    margin: 0.5rem;
}

.textalignLeft {
    text-align: left;
}

.textInfo {
    font-family: Lato;
    font-size: 16px;
    margin: 0.5rem;
}

.textBlockIcon {
    font-family: Lato;
    font-style: Regular;
    font-size: 14px;
    padding-bottom: 3px;
    align-items: center;
    vertical-align: middle;
}

.textBlockIconMorph {
    font-family: Lato;
    font-style: Regular;
    font-size: 14px;
    margin: 0;
    padding-bottom: 3px;
    align-items: center;
    vertical-align: middle;
}

.textContainer {
    border: 1px solid #d9d9d9;
    width: 45%;
    height: 5vh;
    display: flex;
    padding: 1rem;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 1rem;
    cursor: pointer;

    @include media('<=phone') {
        height: 5.5vh;
    }
}

.textContainerSelected {
    @extend .textContainer;
    border: 1px solid #ed6e18;
    background-color: #fdf0e8;
}

.textContainerWider {
    border: 1px solid #d9d9d9;
    width: 85%;
    height: 5vh;
    display: flex;
    padding: 2rem 1rem;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 1rem;
    margin: 2rem auto 0;
    cursor: pointer;
}

.textContainerSelectedWider {
    border: 1px solid #ed6e18;
    width: 85%;
    height: 5vh;
    padding: 2rem 1rem;
    display: flex;
    text-align: center;
    background-color: #fdf0e8;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    margin: 2rem auto 0;
    cursor: pointer;
}

.title {
    font-family: Lato !important;
    color: black;
    font-weight: $fontWeightBold;
}

.width25 {
    width: 45%;
}

.wrap {
    display: flex;
    column-gap: 15rem;
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
    justify-content: center;

    @include media('<=phone') {
        column-gap: 15px;
    }
}
