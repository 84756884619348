@import '../../../../styles/colors';

.container {
    border: 1px solid $colorAlto;
    font-size: 1.2rem;
    padding: 1.5rem;
    border-radius: 1rem;
}

.date {
    display: block;
    margin-bottom: 1rem;
}

.position {
    display: block;
    color: $colorDoveGray;
    margin-bottom: 1rem;
    font-size: 1.2rem;
}
