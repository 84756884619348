.headline {
    margin-bottom: 2.5rem;
}

.buttons {
    margin-top: 4.5rem;
    display: flex;
    justify-content: space-between;

    &>* {
        flex: 0 0 calc(50% - 1.25rem);
    }
}

.center {
    text-align: center;
}