@import '../../../styles/colors';

.root {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.main {
    overflow: auto;
    flex: 1 1 auto;
    padding: 0 2rem 2rem;
}

.timeframe {
    margin-bottom: 2rem;
}

.list {
    max-height: 40rem;
    overflow: auto;
}

.calendar {
    margin-bottom: 2rem;
}

.bottom {
    border-top: 1px solid $colorWildSand;
}

.section {
    margin-top: 1rem;
}

.empty {
    padding-left: 4rem;
    padding-bottom: 1rem;
    position: relative;
    margin-left: 2rem;

    &::before {
        width: 0.6rem;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background: $colorAlto;
        border-radius: 0.3rem;
        content: '';
    }
}

.activity {
    margin-bottom: 2rem;
}
