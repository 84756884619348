@import '../../../styles/layout';
@import '../../../styles/colors';
@import '../../../styles/rwd';

.container {
    max-width: 80rem;
    margin-top: 3rem;
    text-align: center;
    @include media('<=phone') {
        margin-top: 7rem;
    }
}
.mainText {
    margin-bottom: 2.5rem;
    margin-top: 2.5rem;
}
.helpCenter {
    width: 36rem !important;
    margin: 4rem auto 0;
    & > * {
        vertical-align: baseline;
    }
    @include media('<=phone') {
        width: 100% !important;
    }
}
