@import '../../../styles/colors';

.paramBlock {
}

.label {
    color: $colorSilverChalice;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
}

.value {
    font-size: 1.4rem;
}
