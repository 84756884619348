@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.container {
    position: relative;
}

.list {
    max-width: 35.5rem;
    margin: 0 auto;

    @include media('<=phone') {
        overflow: auto;
        max-width: 100%;
        background-color: $colorWhite;
    }
}

.full-height {
    height: 100vh;
}

.show-map {
    padding: 1rem;
    max-height: 60vh;
}

.dash-forward-icon {
    position: relative;
    transform: rotate(-90deg);
    color: $colorSilverChalice;
    width: 1rem;
    height: 1.2rem;
}

.button-container {
    max-width: 37.5rem;
    margin: 0 auto;
    padding: 2rem 0;
    border-top: 1px solid $colorAlto;
}

.active-tracker-header {
    border-bottom: 1px solid $colorAlto;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
}

.back-dash-icon {
    transform: rotate(90deg);
    width: 1.2rem;
    height: 100%;
    flex: 0 0 1.2rem;
    margin: 0 2rem 0 1rem;
}

.tracker-content {
    margin-bottom: 2rem;
}
