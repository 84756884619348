@import '../../../styles/rwd';
@import '../../../styles/colors';

.headline {
    margin-bottom: 3rem;
}

.subHeadline {
    margin-bottom: 1.5rem;
}
.control {
    margin-bottom: 1.5rem;
}
.control-radio {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.billing-info-container {
    border-left: black solid 2px;
    padding-left: 3rem;
    @include media('<=phone') {
        border-left: none;
        padding-left: 0;
    }
}

.billing-info-container-input {
    border-left: black solid 2px;
    padding-left: 3rem;
    @include media('<=phone') {
        border-left: none;
        padding-left: 0;
    }
}
.basic-info-container-input {
    padding-right: 3rem;
    @include media('<=phone') {
        border-right: none;
    }
}
.control-text {
    margin-bottom: 1.5rem;
    margin-top: 0;
}
.info-container {
    padding-bottom: 4rem;
    margin-bottom: 3rem;
}
.radioSuccessMessage {
    color: #99ca3b;
}
.formSuccessMessage {
    color: #99ca3b;
    margin-top: 0;
}

.title-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}
.control-title {
    font-family: Lato !important;
    font-weight: bold;
    margin-bottom: 0;
}
.edit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-left: 1rem;
    padding: 0;
    background: none !important;
    height: 2.2rem !important;
    width: 2.2rem;
    color: $colorFlamingo !important;
    border: 1px $colorFlamingo solid !important;
    border-radius: 6px;
}
.buttons {
    margin-top: 3rem;

    & > * {
        margin-bottom: 1.5rem;
    }
}

.preferredMetric {
    margin: 0 1rem;
}

.info-container label {
    font-family: Lato !important;
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 1.4rem;
    color: black;
}
.values {
    color: black;
    margin-right: 1rem;
}
.form-svg {
    color: $colorFlamingo;
}
