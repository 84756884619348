.geocodeResponses {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 2;

    button {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        height: auto;
        padding: 0.5rem 20%;
        font-size: 1.2rem;
        margin: 0;

        svg {
            position: absolute;
            left: 3%;
        }
    }
}

.singleResponse {
    display: flex;
    flex-direction: column;
}
