@import '../../../styles/colors';

.container {
    cursor: pointer;

    &:hover {
        .mask {
            background-color: $colorAlto;
        }
    }
}

.wrapper {
    display: flex;
}

.checkbox {
    display: none;

    &:checked + .mask {
        & > svg {
            display: block;
        }
        &.stealth {
            border: solid 2px $colorMuteGray;
        }
        &:not(.stealth){
            background-color: $colorAtlantis;
            border-color: $colorAtlantis;
        }
    }
}

.stealth {
    background-color: none;
    border-color: $colorMuteGray;
}

.mask {
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    border: 1px solid $colorAlto;
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;
    transition: 0.2s;
    vertical-align: middle;
    flex: 0 0 2rem;

    &.error {
        border-color: $colorRedOrange;
    }

    & > svg {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        color: $colorWhite;
        display: none;
    }
    
    &.stealth > svg {
        color: $colorMuteGray;
    }
}

.labelText {
    font-size: 1.4rem;
    vertical-align: middle;
    color: $colorDoveGray;
    margin-top: 2px; // Slightly move to the bottom to center with checkbox
}
