@import '../../../styles/colors';

.slider {
    width: 100%;
    position: relative;
}

.rail {
    position: absolute;
    width: 100%;
    height: 0.7rem;
    transform: translate(0%, -50%);
    border-radius: 1rem;
    pointer-events: none;
    background-color: $colorAlto;
}

.handle {
    position: absolute;
    width: 2rem;
    height: 2rem;
    margin-left: -1rem;
    z-index: 8;
    cursor: pointer;
    border-radius: 50%;
    background: $colorFlamingo;
    box-shadow: 0 2px 5px 0 rgba($colorCodGray, 0.15);
    transform: translateY(-50%);
}

.track {
    position: absolute;
    transform: translate(0%, -50%);
    height: 0.7rem;
    z-index: 1;
    background-color: $colorFlamingo;
    cursor: pointer;
}

.labels {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
}

.displays {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;

    & > * {
        width: 7rem;
        line-height: 4rem;
        border: 1px solid $colorAlto;
        border-radius: 1rem;
        font-size: 1.2rem;
        text-align: center;
    }
}
