@import '../../../../styles/colors';
@import '../../../../styles/rwd';
@import '../../../../styles/fonts';

.wrapper {
    display: flex;
    border-radius: 4px;

    @include media('<=phone') {
        top: 1rem;
    }
}

.select {
    appearance: none;
    outline: none;
    border: none;
    font-size: 1.2rem;
    width: 100%;
    padding: 0.5rem;
    padding-right: 2rem;
    font-weight: 500;
    @include media('<=phone') {
        padding-right: 0;
    }
}

.icon {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.flagIcon {
    margin-right: 1rem;
    width: 4rem;
    @include media('<=phone') {
        margin-right: 3px;
    }
}
