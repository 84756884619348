@import '../../../../styles/fonts';
@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.top-row {
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-left: 3rem;
    @include media('<=phone') {
        margin-left: 2rem;
    }
}

.price-period-offert {
    font-weight: $fontWeightBold;
    margin-bottom: 1rem;
    color: $colorAtlantis;
    font-size: 1.5rem;
    display: block;
    @include media('<=phone') {
        font-size: 1.2rem;
    }
}

.price {
    font-size: 1.7rem;
    margin-left: 0.5rem;
}

.price-period {
    font-weight: $fontWeightBold;
    font-size: 2rem;
    margin-top: -1.3rem;
    display: inline-block;
    margin-top: 1rem;
    @include media('<=phone') {
        margin-top: 1.5rem;
        margin-left: 1rem;
        font-size: 1.5rem;
    }
}

.price-period-with-promo {
    font-weight: $fontPrimary;
    font-size: 1.3rem;
    display: inline-block;
}

.features-list {
    margin: 0;
    padding-left: 2rem;
    display: block;

    @include media('<=phone') {
        width: 100%;
    }
}

.flexColumn {
    display: flex;
    flex-direction: column;

    @include media('<=phone') {
        position: relative;
    }
}

.flex {
    display: flex;
    margin: 0.5rem 0;

    @include media('<=phone') {
        position: relative;
        margin: unset;
    }
}

.feature {
    margin-left: 1rem;
    line-height: 2.2rem;
    list-style: none;
    width: 85%;

    @include media('<=phone') {
        margin-left: 0;
        line-height: 2rem;
        width: 85%;
    }
}

.display-prices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    padding-bottom: 2rem;

    @include media('<=phone') {
        width: 50%;
    }
}

.subtext {
    font-size: 1.2rem;
    color: $colorSilverChalice;
    margin: 1.5rem 0 1rem;
}
