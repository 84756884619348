.title {
    font-weight: bolder;
    text-align: center;
    margin: 3rem 0;
    font-size: 1.5rem;
}

.text {
    text-align: center;
    margin: 3rem 0;
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.button {
    margin: 1rem 2rem;
    flex: 1 1 auto;
}
