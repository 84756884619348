@import '../../../styles/colors';
@import '../../../styles/rwd';

.page-container {
    height: calc(100vh - 6rem); // Fullpage minus header height

    @include media('<=tablet') {
        height: calc(100vh - 11rem); // Fullpage minus header height
    }

    @include media('<=phone') {
        height: calc(100vh - 6rem); // Fullpage minus header height
    }
}

.centered {
    text-align: center;
}

.gridModal {
    display: flex;
    gap: 1rem;

    @include media('<=phone') {
        flex-flow: column;
    }

}

.button {
    padding: 1rem;
}

.steps {
    max-width: 50%;
    padding-left: 12%;

    @include media('<=phone') {
        flex-wrap: wrap;
        justify-content: center;
        max-width: 80rem;
        padding: 0 1rem;
    }
}
