@import '../../../../../styles/colors';

.toggle-button {
    width: 6rem;
    height: 6rem;
    overflow: hidden;
    position: absolute;
    bottom: 100%;
    left: 50%;
    display: block;
    margin-left: -3rem;
    margin-bottom: 0;
    padding: 0;
    z-index: 1;
    border-bottom: 1px solid $colorWhite;

    &::after {
        content: '';
        width: 6rem;
        height: 6rem;
        position: absolute;
        display: block;
        top: 3.5rem;
        left: 0;
        border-radius: 3rem;
        z-index: -1;
        background: $colorWhite;
        border: 1px solid $colorAlto;
    }
}

.button-icon {
    top: 2rem;
    position: relative;
    color: $colorSilverChalice;
    transform: rotate(180deg);

    &.open {
        transform: none;
    }
}
