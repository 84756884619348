@import '../../../styles/colors';

.container {
    border-radius: 0.5rem;
    border: solid 1px $colorAlto;
    padding: 0 1rem;
}

.headline-section {
    padding: 2rem 0;
    border-bottom: 1px solid $colorAlto;
}

.content {
    padding: 1.5rem 1rem;
}
