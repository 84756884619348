@import '../../../styles/colors';
@import '../../../styles/fonts';

.notificationBar {
    display: flex;
    align-items: center;
    min-height: 5rem;
    text-align: center;
    justify-content: center;
    padding: 0 2rem;
    padding-right: 5rem;
    position: relative;

    &.error {
        background-color: $colorRedOrange;
        color: $colorWhite;
    }

    &.success {
        background-color: $colorAtlantis;
        color: $colorWhite;
    }
}

.cross {
    transform: translateY(-50%) rotate(45deg);
    position: absolute;
    cursor: pointer;
    top: 50%;
    right: 2rem;
}
