@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.not-cancelable {
    color: $colorButtonDisabled;
    margin: 0;
}
.buttonCancel {
    margin-right: 1rem;
    @include media('<=phone') {
        margin-right: 0;
        width: -webkit-fill-available !important;
    }
}
