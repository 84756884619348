@import '../../../styles/colors';

.input-container {
    display: flex;
    align-items: center;
}

.add-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 5px;
    border: 1px rgb(221, 221, 221) solid;
    margin: 1rem 0 0 0;
}

.mailInputField {
    display: block;
    width: 100%;
    background: transparent;
    border: none;
    border-radius: 1rem;
    box-shadow: none;
    font-size: 1.4rem;
    padding: 0 1rem;
    transition: 0.2s;
}
.submit-button {
    flex: 0 0 0rem;
    margin: 0;
    margin-left: 0px;
    margin-left: 2rem;
    padding: 0;
    font-size: 1.2rem;
    height: 2.2rem !important;
    width: auto;
    background: none !important;
    border: 1px #99ca3b solid !important;
    border-radius: 6px;
    color: #99ca3b !important;
    font-weight: 100;
    padding: 0.4rem 0.6rem 0.4rem 0.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.submit-button .form-svg {
    margin-left: 1rem;
}
.form-option-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px rgb(221, 221, 221) solid;
    padding-bottom: 0.8rem;
    margin: 1rem 0 0 0;
}
.registered-info {
    display: flex;
    align-items: center;
}
.add-buttons-container {
    display: flex;

    margin: 1rem 1rem 0 0;
}
.mail-phone-picto {
    margin-right: 1rem;
    color: rgb(126, 126, 126);
}
.phone-picto {
    height: 1.6rem;
    width: auto;
    margin-right: 1rem;
    color: rgb(126, 126, 126);
}
.delete-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-left: 2rem;
    padding: 0;
    height: 2.2rem !important;
    width: 2.3rem;
    background: none !important;
    border: 1px rgb(161, 161, 161) solid !important;
    border-radius: 6px;
    color: rgb(161, 161, 161) !important;
}
.mailSuccessMessage {
    display: none;
    color: #99ca3b;
}
.phoneSuccessMessage {
    display: none;
    color: #99ca3b;
}
.mailActive {
    display: block;
}
.phoneActive {
    display: block;
}
.cancel-button {
    margin: 0;
    margin-left: 2rem;
    padding: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: 1px rgb(161, 161, 161) solid !important;
    color: rgb(161, 161, 161) !important;
    height: 2.2rem !important;
    width: 2.2rem !important;
    background: none !important;
}
.form-buttons-container {
    display: flex;
    flex-direction: row;
}
.edit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-left: 1rem;
    padding: 0;
    background: none !important;
    height: 2.2rem !important;
    width: 2.2rem;
    color: #fc4c02 !important;
    border: 1px #fc4c02 solid !important;
    border-radius: 6px;
}
.section {
    margin-bottom: 3rem;
}

.button {
    min-width: 19rem;
}
.warning {
    bottom: -1rem;
    color: $colorSilverChalice;
    right: 0;
    display: flex;
    margin-top: 2rem;
    flex-direction: column;
    width: 100%;
}
.link {
    color: $colorFlamingo;
    width: fit-content;
    width: -moz-fit-content;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
