@import '../../../styles/colors.scss';
@import '../../../styles/rwd';

.headline {
    margin: 2rem 0 2rem;
    @include media('<=phone') {
        margin: 8rem 0 2rem;
    }
}

.form {
    max-width: 36rem;
    margin: 0 auto;
}

.login-info {
    margin: 1rem 0;
}
.forgot-password {
    margin-bottom: 3rem;
    cursor: pointer;
    color: $colorFlamingo;
}
.grey-line {
    border-bottom: 1px solid #bbb;
    margin: auto;
    width: 65%;
}
.border-top {
    border-top: 2px solid #bbb;
}
.link-to {
    color: $colorFlamingo;
}
.button-retry {
    background-color: $colorWhite !important;
    border: 1px solid $colorButtonDisabled !important;
    color: $colorButtonDisabled !important;
    margin: auto !important;
    width: 90% !important;
}
.modal-close-button {
    padding: 10px;
}
.modal-width {
    width: 34rem;
}
.button-width {
    width: 90% !important;
}
