@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/rwd';

html {
    font-size: 62.5%; // Sets 1rem = 10px by default
}

body {
    color: $colorCodGray;
    margin: 0;
    padding: 0;
    font-family: $fontPrimary;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.4rem; // Sets default font size to 14px
    box-sizing: border-box;
}

* {
    box-sizing: inherit;
    -webkit-overflow-scrolling: touch;
}

/**
    Default resets
 */
button,
a,
input,
textarea {
    box-shadow: none;
    outline: none;
    border: none;
    text-decoration: none;
    color: inherit;
    background: transparent;

    &:focus,
    &:active {
        box-shadow: none;
        outline: none;
        border: none;
        text-decoration: none;
        color: inherit;
    }
}

button,
a {
    cursor: pointer;
}

img {
    display: block;
    width: 100%;
}

/* These elements are called on hipay-step.component.tsx.
As they are loading from external lib (and not React usual cycle of life),
we need to set them in static css and not usual component module.scss files */
.HiPayField--focused + .hipay-field-label {
    color: $colorFlamingo !important;
}
.HiPayField--focused + .hipay-field-label + .hipay-field-baseline {
    border-bottom: solid 1px $colorFlamingo;
}
