@import '../../../styles/colors';
@import '../../../styles/rwd';

.button {
    width: 80%;
    margin: auto;
    margin-top: 2rem;
}
.container {
    background-color: #ed6e18;
}
.header {
    height: 12vh;
    padding: 3rem;
    background-color: #ed6e18;
    text-align: center;
    color: white;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 26px;
    line-height: 137%;
    @include media('<=phone') {
        height: 20vh;
    }
}
.icon {
    position: absolute;
    right: 0;
    top: 2%;
    @include media('<=phone') {
        top: 7%;
    }
}
.closeButton {
    color: #fdf0e8;
    position: absolute;
    right: 1rem;
    z-index: 99;
    cursor: pointer;
}
.mainContent {
    background-color: white;
    display: flex;
    flex-flow: column;
    border-radius: 1rem;
}
.mainContentContainer {
    width: 85%;
    margin: auto;
}
.mainContentTitle {
    width: 50%;
    padding-top: 2rem;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}
.modal {
    position: fixed;
    width: 57rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: $colorWhite;
    border-radius: 2rem;
    z-index: 1200;
    max-height: 90vh;
    overflow: auto;
    @include media('<=phone') {
        width: 30rem;
    }
}
.overlay {
    position: fixed;
    background: rgba($colorCodGray, 0.2);
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 1200;
}
.optionFeatureBlock {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.optionFeatureIcon {
    text-align: center;
    background: #fdf0e8;
    width: 5rem;
    display: flex;
    vertical-align: -webkit-baseline-middle;
    height: 5rem;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
}
.optionFeatureTextBlock {
    width: 85%;
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
}
.optionFeatureTitle {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 150%;
    color: #ed6e18;
}
.optionFeatureText {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
}
.textContainer {
    width: 75%;
    text-align: center;
    margin: auto;
    margin-bottom: 3rem;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}
.subTextContainer {
    width: 90%;
    text-align: center;
    margin: auto;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #9f9f9f;
    margin-bottom: 4rem;
}
.svg {
    width: 25rem;
    @include media('<=phone') {
        width: 15rem;
    }
}
