.infoIcon {
}

.icon {
    width: 1.6rem;
    margin: 0 auto 0.5rem;
}

.label {
    text-align: center;
    font-size: 1.2rem;
    display: block;
}
