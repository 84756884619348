@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.container {
    max-width: 100%;
    padding: 0 12%;
    @include media('<=phone') {
        padding: 0 2%;
        margin-top: 6rem;
    }

    margin: 0;
    font-size: 1.6rem;

    .backSpan {
        @include media('<=phone') {
            display: flex;
        }
        cursor: pointer;

        .headerBackArrow {
            display: flex;
            margin: 4rem 0;
            font-weight: bold;
            font-size: 1.6rem;

            .backIcon {
                color: $colorFlamingo;
                margin-right: 2rem;
                width: auto;
                height: 2rem;
                -webkit-transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                -o-transform: rotate(90deg);
                transform: rotate(90deg);
            }
        }
    }

    .explanation {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @include media('<=phone') {
            align-items: center;
            text-align: center;
        }

        .title {
            font-weight: 700;
            font-size: 2rem;
        }
    }

    .inputs {
        display: flex;
        margin: 2rem 0;
        @include media('<=phone') {
            justify-content: center;
        }

        .giftCardInput {
            height: 4rem;
            width: 38rem;
            border-radius: 1rem 0 0 1rem;
            margin: 0;
            @include media('<=phone') {
                width: auto;
            }
        }

        .giftCardButton {
            border-radius: 0 1rem 1rem 0;
            color: white;
            font-weight: 700;
            font-size: 1.8rem;
            height: 4rem;
            margin: 0;
        }
    }

    .giftCardIcon {
        margin-top: 4rem;
        width: 492px;
        height: 261px;

        @include media('<=phone') {
            width: initial;
            height: initial;
        }
    }

    .help {
        @include media('<=phone') {
            text-align: center;
        }
    }
}
