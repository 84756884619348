@import '../../../styles/colors';

.activeIndicator {
    color: $colorSilverChalice;
    font-size: 1rem;

    &::before {
        margin-right: 0.5rem;
        display: inline-block;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        vertical-align: middle;
        content: '';
    }
}

.active {
    &::before {
        background: $colorChristi;
    }
}

.inactive {
    &::before {
        background: $colorSilverChalice;
    }
}

.pending {
    &::before {
        background: $colorGoldTips;
    }
}
