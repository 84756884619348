@import '../../../../styles/colors';

.good {
    color: $colorChristi;
}

.medium {
    color: $colorGoldTips;
}

.bad {
    color: $colorRedOrange;
}
