@import '../../../styles/colors';

.iconBadge {
    height: 4rem;
    width: 4rem;
    position: relative;
    border-radius: 4rem;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 50%;
    top: 50%;
    width: 3rem;
    height: 3rem;
    transform: translate(-50%, -50%);
}

.cornerIndicator {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 1rem;
    border: 2px solid $colorWhite;
    position: absolute;
    left: -3px;
    top: -3px;

    &.green {
        background-color: $colorChristi;
    }

    &.red {
        background-color: $colorRedOrange;
    }
}
