@import '../../../styles/fonts';

.logout-button {
    font-size: 1.4rem;
    font-weight: $fontWeightBold;
    padding: 0;
    cursor: pointer;
    margin-right: 1rem;
    text-align: left;

    & > * {
        vertical-align: middle;
    }

    & > span {
        margin-left: 1rem;
    }
}
