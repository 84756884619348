@import '../../../styles/layout';
@import '../../../styles/colors';
@import '../../../styles/rwd';

.pageContainer {
    height: 100vh;
}

// Push header shadow over the map
.header {
    position: relative;
}

.columnWrapper {
    height: calc(100vh - 6rem); // Fullpage minus header height
    display: flex;
    align-items: stretch;

    @include media('<=tablet') {
        height: calc(100vh - 11rem); // Fullpage minus header height
        flex-direction: column;
    }

    @include media('<=phone') {
        height: calc(100vh - 6rem); // Fullpage minus header height
    }
}

.trackersList {
    padding: 0;
    height: calc(100% - 6.2rem); // minus button height in the bottom
    overflow: auto;
}

.sidebar {
    width: $sidebarWidth;
    height: 100%;
    position: relative;
}

.sidebarContainer {
    height: 100%;
    max-width: 35rem;
    margin: 0 auto;
}

.innerSidebar {
    border-left: 1px solid $colorAlto;
    padding: 2rem;
    width: $innerSideberWidth;
    background: $colorWhite;

    &.small {
        width: 37rem;
    }
}

.mapContainer {
    width: auto;
    flex: 1 1 auto;
    position: relative;

    @include media('<=tablet') {
        order: 0;
    }
}

/**
    Extra markup because on mobile Webkit map didn't get height of flex container
 */
.mapInnerContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}

.addTrackerButtonContainer {
    padding: 0 1.5rem;
    border-top: 1px solid $colorAlto;
    border-bottom: 1px solid $colorAlto;
}

.mobileTrackers {
    z-index: 700;
    position: fixed;
    bottom: 0%;
    width: 100%;
    flex: 0 0 6rem;
}

//iPhone 12 and iPhone 12 Pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
    .mobileTrackers {
        flex: 0.2 0 6rem;
    }
}

//iPhone 12 Pro Max
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
    .mobileTrackers {
        flex: 0.2 0 6rem;
    }
}

.controls {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    right: 2.5rem;
    top: 4rem;
    z-index: 1000;
    height: 100%;

    @include media('<=phone') {
        top: auto;
    }
}

.liveModeModal {
    width: 29rem;
    box-shadow: rgba($colorCodGray, 0.15);
    position: absolute;
    right: 9rem;
    top: 4rem;
    z-index: 1200;

    @include media('<=phone') {
        top: 9rem;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
