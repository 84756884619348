@import '../../../styles/fonts';
@import '../../../styles/colors';
@import '../../../styles/rwd';

.item {
    background: $colorWhite;
}

.itemContainer {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 1rem 0;
    @include media('<=phone') {
        margin-left: 1rem;
        margin-right: 1rem;
    }
    & * {
        user-select: none; // Prevent text to be selected when clicked many times
    }
}
.menuBr {
    display: none;
}
.labelsContainer {
    margin-right: auto;
}

.iconContainer {
    margin-right: 1rem;
}

.mainLabel {
    display: block;
    font-family: Lato !important;
    font-weight: $fontWeightBold;
}

.subText {
    display: block;
    color: $colorSilverChalice;
    font-size: 1.2rem;
}

.toggleButton {
    transition: 0.2s;

    &.active {
        transform: rotateX(180deg);
    }
}
