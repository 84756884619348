@import '../../../styles/colors';
@import '../../../styles/rwd';

.overlay {
    position: fixed;
    background: rgba($colorCodGray, 0.2);
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 1200;
}

.modal {
    position: fixed;
    width: 57rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: $colorWhite;
    padding: 3rem;
    border-radius: 1rem;
    z-index: 1200;
    max-height: 90vh;
    overflow: auto;
    @include media('<=phone') {
        width: 30rem;
        padding: 2.5rem;
    }
}

.closeButton {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    padding: 0;
    color: $colorOsloGray;
}
