@import '../../../../styles/colors';

.wrapper {
    position: relative;
    width: 4rem;
    height: 4rem;
}

.progress {
    width: 100%;
    height: 100%;
}

.progress-trail {
    stroke: $colorFlamingo;
}

.progress-fill {
    stroke: $colorWhite;
}

.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    color: $colorWhite;
    width: 2rem;
    height: 2rem;
    transform: translate(-50%, -50%);
}

.active {
    color: $colorWhite;
}

.blinking {
    animation: blink 1s infinite;
}

@keyframes blink {
    from {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
