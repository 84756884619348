@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.status {
    font-family: Lato !important;
    color: $colorDoveGray;
    background: $colorWildSand;
    display: block;
    padding: 0.5rem;
    border-radius: 4px;
    font-weight: $fontWeightBold;
}

.date {
    margin-right: 2rem;
}

.name {
    margin-right: auto;
}
