@import '../../../styles/colors';

.row {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
    text-align: center;
}

.input {
    display: none;
    border: 0 solid #ccc;
    display: inline-block;
    cursor: pointer;

    input[type='file'] {
        display: none;
    }
}

.iconUrl {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
}

.item {
    position: relative;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 0 3px $colorWhite, 0 0 0 5px $colorAlto;
    }

    &.active {
        box-shadow: 0 0 0 3px $colorWhite, 0 0 0 5px $colorFlamingo;

        &:hover {
            box-shadow: 0 0 0 3px $colorWhite, 0 0 0 5px $colorFlamingo;
        }
    }
}
