@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/rwd';

.slider {
    position: relative;
    width: max-content;
    height: 30%;
    visibility: visible;
    opacity: 1;
    transition: height 0.2s linear, width 0.5s ease, opacity 0.5s linear,
        visibility 0s linear;

    &.isHidden {
        width: 0;
        height: 3rem;
        opacity: 0;
        visibility: hidden;

        @include media('<=phone') {
            height: 4rem;
        }
    }
}

.rail {
    background: $colorAlto;
    border-radius: 0.5rem;
    height: 0.7rem;
    margin-left: -1rem;
    margin-right: -1rem;
}

.handle {
    position: absolute;
    width: 2rem;
    height: 2rem;
    margin-left: -1rem;
    z-index: 12;
    cursor: pointer;
    border-radius: 50%;
    background: $colorFlamingo;
    box-shadow: 0 2px 5px 0 rgba($colorCodGray, 0.15);
    margin-top: -1.3rem;
}

.values {
    margin-top: 0.8rem;
    margin-bottom: 2rem;
}

.value {
    font-size: 1.3rem;
    color: $colorSilverChalice;
    position: absolute;
    text-align: center;
    transition: 0.2s;
    cursor: pointer;

    &.active {
        color: $colorFlamingo;
        font-weight: $fontWeightBold;
    }

    /**
        Clickable box
     */
    &::after {
        content: '';
        position: absolute;
        left: 50%;
        width: 2rem;
        height: 2rem;
        top: -2rem;
        margin-left: -1rem;
    }

    /**
        Small dot on rail
     */
    &::before {
        content: '';
        width: 2px;
        height: 3px;
        border-radius: 50%;
        background: $colorWhite;
        top: -1.5rem;
        position: absolute;
        left: 50%;
        z-index: 10;
    }
}
.sliderTitle {
    text-align: center;
    font-family: Lato !important;
    margin: 0 auto 0.8rem auto;
    width: max-content;
    font-size: 1.4rem;
    color: $colorFlamingo;
    font-weight: bold;
}

.sliderTitle::first-letter {
    text-transform: uppercase;
}

.sliderWarning:first-letter {
    text-transform: uppercase;
}


.intervalTitleToggleDeactivated {
    font-family: Lato !important;
    width: max-content;
    display: flex;
    vertical-align: top;
    padding-right: 1em;
    margin: 0;
    white-space: nowrap;
}

.intervalTitleToggleDeactivated::first-letter {
    text-transform: uppercase;
}

.intervalTitleToggleDeactivatedToggleActivated {
    font-family: Lato !important;
    width: max-content;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    margin-right: 13px;
    margin-top: 0;
    white-space: nowrap;
    margin-bottom: 0;
}

.intervalTitleToggleDeactivated:first-letter {
    text-transform: uppercase;
}

.intervalContainerToggleActivated {
    height: 100%;
    display: flex; 
    margin-top: 15%;
    flex-Direction: row;
    align-Items: center;
    align-self: flex-end;
}

.intervalContainerToggleDeactivated {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.track {
    position: absolute;
    height: 1rem;
    z-index: 1;
    top: 0;
    background: $colorFlamingo;
    border-radius: 5px;
    cursor: pointer;
}

.slider-container {
    margin-bottom: 1.5rem;
    max-width: 35rem;
}
.preview {
    display: flex;
    align-items: center;
    & .preview_text {
        line-height: initial;
        color: $colorFlamingo;
        font-size: 0;
        margin: 0;
        transition: font-size 0.5s ease;
        &.isVisible {
            font-size: 2rem;
        }
    }
}
