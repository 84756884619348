@import '../../../styles/fonts';
@import '../../../styles/colors';

.text {
    display: block;
    line-height: 1.6;
}

.noMargin {
    margin: 0;
}

.standardMarginBottom {
    margin: 0 0 1em 0;
}

.size-12 {
    font-size: 1.2rem;
}

.size-14 {
    font-family: Lato !important;
    font-size: 1.4rem;
}

.size-16 {
    font-family: Lato !important;
    font-size: 1.4rem;
}

.bold {
    font-family: Lato !important;
    font-weight: $fontWeightBold;
}

.center {
    text-align: center;
}

.orange {
    color: $colorFlamingo;
}

.gray {
    color: $colorSilverChalice;
}

.inlineBlock {
    display: inline-block;
}
