@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.container {
    max-width: 100%;
    padding-left: 12%;
    padding-right: 12%;
    margin: 0;

    @include media('<=phone') {
        padding-left: 5%;
        padding-right: 5%;
    }
}

.cgu-text {
    color: #ed6e18 !important;
}

.headline {
    margin-bottom: 1rem;
    width: 50%;
    text-align: start;

    @include media('<=phone') {
        width: 100%;
    }
}

.main-subtitle {
    margin-bottom: 3rem;
    font-weight: normal;
    width: 50%;
    text-align: start;
    color: $colorFlamingo;

    @include media('<=phone') {
        width: 100%;
    }
}

.subheadline {
    margin-bottom: 1.5rem;

    @include media('<=phone') {
        margin-top: 2rem;
    }
}

.grid {
    @include media('<=phone') {
        &>* {
            margin-top: 2.5rem;
        }
    }
}

.button {
    display: flex;
    justify-content: flex-end;
    width: 50%;

    @include media('<=phone') {
        padding: 0 1rem;
        bottom: 0;
        right: 0;
        display: block;
        width: 100%;
        background-color: #ebebeb;
    }
}

.submitButtonMobile {
    display: none;

    @include media('<=phone') {
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 0;
        height: 4rem;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
    }
}

.submitButtonDesktop {
    display: flex;

    @include media('<=phone') {
        display: none;
    }
}

.typeFormContainer {
    width: 36rem;
    margin: 0 auto;
}

.animalChoicesContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 1rem auto 6rem;
}

.icon {
    margin: 3rem auto 0;
}

.iconTitleContainerSelected {
    border: 1px solid #ed6e18;
    width: 18rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 70rem;
    cursor: pointer;
}

.animalTypeSubmitButton {
    width: 36rem;
}

.iconTitleContainer {
    width: 18rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.animal-type-subtitle {
    margin-bottom: 2rem;
    font-weight: normal;
}

.animal-type-subtitle-selected {
    margin-bottom: 2rem;
    font-weight: normal;
    text-align: center;
    color: #ed6e18;
}

.mobileButton {
    @include media('<=phone') {
        height: 4rem;
    }
}

.width50 {
    width: 50%;

    @include media('<=phone') {
        width: 100%;
    }
}

.cguButton {
    color: $colorFlamingo;
    cursor: pointer;
}

.single-offer {
    @include media('<=phone') {
        margin: auto;
        margin-bottom: 1rem;
        height: 15rem;
    }

    margin-bottom: 1rem;
    height: 11rem;
    cursor: pointer;
}

.single-offer-with-top-left-border {
    @include media('<=phone') {
        margin: auto;
        margin-bottom: 1rem;
    }

    margin-bottom: 1rem;
    position: relative;
    cursor: pointer;
    height: 10rem;
}

.single-offer-with-top-left-border::before,
.single-offer-with-top-left-border::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
}

.single-offer-with-top-left-border::before {
    border-width: 2.7em;
    border-top-right-radius: 10%;
    border-right-color: #99d529;
    border-top-color: #99d529;
}

.money-back-text {
    font-size: 1.2rem;
    color: $colorSilverChalice;
    margin: 1.5rem 0;
    padding-right: 4rem;
}

.noel-offer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: radial-gradient(65.77% 6019.17% at 27.26% 34.23%,
            #eedc9a 0%,
            #cca350 100%);
    margin-top: -2rem; // cancel steps margin bottom
    margin-bottom: 2rem;

    & .noel-offer-icon-revert {
        transform: scaleX(-1);
    }

    & .noel-offer-text {
        flex-direction: column;
        text-align: justify;
        text-align-last: center;
        width: 41%;

        &>p:first-of-type {
            font-family: Lato !important;
            font-weight: bold;
        }
    }

    @include media('<=phone') {
        flex-direction: column;
        padding: 1rem;

        &>svg:first-of-type,
        &>svg:last-of-type {
            display: none;
        }

        & .noel-offer-text {
            width: 100%;
        }
    }
}

.summary {
    background-color: #ebebeb;
    width: 60%;
    border-radius: 2rem;
    left: calc(-50vw + 50%);
    height: fit-content;
    padding: 2rem;
    margin-left: 5rem;

    @include media('<=phone') {
        position: relative;
        width: 100vw;
        border-radius: 0;
        margin-top: 0;
        margin-left: 0;
        padding: 20px 20px 67px 20px;
    }
}

.periodHeadline {
    font-size: 2rem;
    font-weight: bold;
}