@import '../../../styles/colors';
@import '../../../styles/rwd';

.headline {
    margin-bottom: 3rem;
    margin-top: 4rem;

    @include media('<=phone') {
        margin-top: 1rem;
        margin-bottom: 1rem;

    }
}

.sidesPadding3rem {
    padding: 0 3rem;

    @include media('<=phone') {
        padding: 0;
    }
}

.container {
    display: flex;
    justify-content: space-evenly;

    @include media('<=phone') {
        flex-direction: column;
    }
}

.reverse {
    margin-top: 3rem;

    @include media('<=phone') {
        flex-direction: column-reverse;
    }
}

.description {
    flex: 1 1 0;
    line-height: 1.5;

    &>p:first-of-type {
        font-family: Lato !important;
        font-weight: bold;
        font-size: 2rem;
    }

    margin-right: 1rem;
}

.centered {
    flex: none;
    justify-content: center;
    text-align: center;
}

.available-pm {
    flex: 1 1 0;
    line-height: 1.5;
    margin-left: 1rem;

    &>p:first-of-type {
        padding-top: 1rem;
    }

    &>img {
        max-width: 10rem;
        display: inline-flex;
        margin: 1rem;
    }
}

.available-deb {
    display: flex;
    align-items: center;

    &>img {
        max-width: 10rem;
        display: inline-flex;
        margin: 1rem;
    }

    @include media('<=phone') {
        display: block;

    }
}

.backIcon {
    transform: rotate(90deg);
    width: 1rem;
    height: 1rem;
}

.buttonModify {
    flex: 1 1 0;
    margin: 1.5rem;
    width: 50%;
    color: #ED6E18 !important;
    background: #ED6E1820;
    box-shadow: 0 0 0rem #eee;

    @include media('<=phone') {
        width: 100%;
        flex: auto;

    }
}

.return,
.update {
    flex: 1 1 0;
    margin: 1.5rem;

    @include media('<=phone') {
        flex: auto;
    }
}

.return>button {
    width: 100%;
    margin: 0;
}