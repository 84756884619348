@import '../../styles/rwd';

.container {
    width: 36rem;
    margin: 0 auto;
    padding: 0 1.5rem;
}

.primary-headline {
    margin: 3rem 0;
}

.secondary-headline {
    margin-bottom: 1.5rem;
}

.form {
    margin-bottom: 3rem;
}

.terms-link {
    margin-top: 3rem;
}
.server_error {
    background-color: #dd4b39;
    color: #fff;
    padding: 1rem;
    border-radius: 2px;
    margin-bottom: 2rem;
    text-align: center;
}
button {
    margin: 1rem 0;
}

input {
    margin-bottom: 1rem;
}
