@import '../../../styles/fonts';
@import '../../../styles/colors.scss';
@import '../../../styles/rwd';

.container {
    max-width: 100rem;
    margin: auto;
    text-align: left;
    margin-top: 8rem;
}

.paddingBottom {
    padding-bottom: 40rem;
}

.text {
    margin: 2rem;
    margin-bottom: 3.5rem;
}

.buttons {
    width: 50%;
    margin: auto;
    margin-bottom: 2.5rem;
    flex-direction: row-reverse;
    @include media('<=phone') {
        width: 90%;
        margin-bottom: 1.5rem;
    }
}
.buttonFixed {
    position: fixed;
    margin: auto;
    right: 0%;
    bottom: 0%;
    z-index: 1000;
    background-color: white;
    width: -webkit-fill-available;
}

.buttonOnGoForward {
    border: none !important;
    color: $colorFlamingo !important;
    background-color: transparent !important;
    pointer-events: cursor;
}
