.tracker-description {
    text-align: center;
    margin-bottom: 3rem;
}

.tracker-image {
    width: 8rem;
    margin: 0 auto 3rem;
}

.ok-button {
    width: 15rem;
    display: block;
    margin: 0 auto;
}
