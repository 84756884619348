@import '../../../styles/colors';
@import '../../../styles/rwd';

.infoPoint {
    margin-bottom: auto;
    position: relative;
}

.infoSVG {
    width: 2rem;
    height: 2rem;
}

.infoSVG:hover + .info {
    opacity: 0.9;
}

.info {
    position: absolute;
    right: 0.2rem;
    width: max-content;
    background-color: #262626;
    color: #f0f0f0;
    padding: 1rem 1rem 1rem 2.5rem;
    margin: 0;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    opacity: 0;
    transition: opacity 0.12s ease;
    z-index: 1;
    font-size: 1.1rem;

    @include media('<=phone') {
        font-size: 1.1rem;
    }
}

.spacing {
    padding: 10px;
}

.root {
    border-radius: 12px;
    border: 1px solid $colorAlto;
    display: block;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: 0.2s;
    box-shadow: 0px 4px 4px rgba(192, 192, 192, 0.25);

    &.checked {
        border-color: $colorChristi;

        .marker {
            background: $colorChristi;
            border-color: $colorChristi;

            & > .tick {
                color: $colorWhite;
                display: block;
            }
        }
    }
}

.container {
    display: flex;
    align-items: center;
    padding: 10px 1.5rem;
    height: inherit;
}

.nativeCheckbox {
    display: none;
}

.children {
    flex: 1 1 auto;
}

.marker {
    flex: 0 0 2rem;
    width: 2rem;
    height: 2rem;
    border: 1px solid $colorAlto;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.2s;

    &.radio {
        border-radius: 1rem;
    }

    &.checkbox {
        height: 2rem;
        width: 2rem;
        border-radius: 3rem;
        margin-right: 0rem;
    }

    & > .tick {
        display: none;
        width: 1rem;
        height: 1rem;
    }
}
