@import '../../styles/colors';
@import '../../styles/rwd';

.container {
    max-width: 100rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.headline {
    color: #fc4c02;
    margin: 0;
    display: block;
}
.greetCommonText {
    text-align: center;
    margin: 0;
}
.commonText {
    text-align: center;
}
.commonTextWrapper {
    margin: 2.5rem 0;
}
.redText {
    text-align: center;
    color: #e27659;
    margin: 0 0 2.5rem;
}
.mapCommonText {
    height: 6rem;
    text-align: center;
}
.skip {
    position: absolute;
    left: 2rem;
    top: 8rem;
    @include media('<=phone') {
        margin-top: 4rem;
        display: flex;
    }
    color: #969696;
    font-weight: 200;
    cursor: pointer;
}
.skipFlow {
    text-align: center;
    color: #969696;
    font-weight: 200;
    margin: 1rem 0;
}

.turnOn {
    width: 55rem;
    align-self: center;
}
.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 12rem;
}
.nextButton {
    width: 20rem;
    margin: auto;
    height: 3rem;
    font-weight: 100 bold;
    &:hover,
    &:active,
    &:focus {
        background: $colorFlamingo !important;
        border: 2px solid $colorFlamingo !important;
        opacity: 1 !important;
    }
}
.backButton {
    width: 20rem;
    margin: auto;
    height: 3rem;
    font-weight: 100 bold;
    &:hover,
    &:active,
    &:focus {
        color: $colorFlamingo !important;
        opacity: 1 !important;
    }
}
.next {
    width: 20rem;
    margin: 3rem auto 0;
    font-weight: 100;
}
.tutorialStartPositionner {
    margin-top: 18rem;
    margin-bottom: 2rem;
}
.screenWrapper {
    min-height: 47rem;
    text-align: center;

    @include media('<=phone') {
        min-height: 50vh;
    }
}
.map {
    align-self: center;
}
.endButton {
    width: 15rem;
    height: 3rem;
}
.mapButton {
    width: 15rem;
    margin-left: auto;
    margin-right: 5rem;
    height: 3rem;
    font-weight: 100;
}

.bulletPointWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 15rem;
    align-self: center;
    margin: 1rem 0;
}

.bullet-point {
    height: 1rem;
    width: 1rem;
    background-color: #d9d9d9;
    border-radius: 50%;
    transition: all 0.3s ease;
}

.currentStep {
    height: 1.5rem;
    width: 1.5rem;
    background-color: #586066;
}
.greet {
    color: #fc4c02;

    @include media('<=phone') {
        margin-top: 5rem;
    }
}
.alertPopup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.7rem;
    text-align: justify;
    text-align-last: center;
}

.raExplanation {
    margin: 4rem 0;
}
.raPrice {
    margin-top: 0;
    color: $colorDoveGray;
}

.bold {
    font-family: Lato !important;
    font-weight: bold;
}
