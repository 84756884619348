@import '../../../styles/colors';

.wrapper {
    display: flex;
    align-items: center;
}

.indicator {
    width: 1.8rem;
    height: 1.8rem;
    flex: 0 0 1.8rem;
    margin-right: 0.5rem;
    position: relative;
    background: rgba($colorFlamingo, 0.1);
    border-radius: 50%;

    &::after {
        width: 50%;
        height: 50%;
        border-radius: 50%;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(50%, 50%);
        background: $colorFlamingo;
    }
}

.hint {
    margin-top: 0.5rem;
}
