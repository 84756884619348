@import '../styles/rwd';

.container {
    @include media('landscape', '<=700px') {
        @media (min-device-aspect-ratio: 1/1) {
            display: none;

            & + .noSupport {
                display: flex;
            }
        }
    }
}

.noSupport {
    position: fixed;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    font-size: 1.6rem;
    margin: 0;

    display: none;
}

[hidden] {
    display: none !important;
}
