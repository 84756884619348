.info-label {
    & > * {
        vertical-align: middle;
        margin-left: 0.5rem;
        cursor: pointer;
    }
}

.form {
    padding-bottom: 2rem;
}

.return-app-link {
    font-weight: bold;
    display: flex;
    justify-content: center;
}
