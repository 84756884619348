@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/rwd';

/**
    Consider extending input props instead
 */
.roundButton {
    width: 4rem;
    padding: 0;
}

.locationSection {
    position: relative;
    margin-bottom: 1.5rem;
}

.radiusSection {
    margin-bottom: 1.5rem;
}

.grid {
    display: flex;
    align-items: center;

    & > * {
        margin-right: 1rem;
    }
}

.radiusInput {
    width: 8rem;
    text-align: right;
}

.radiusNativeField {
    padding-right: 2rem;
}

.radiusInputSuffix {
    line-height: 1.6rem;
    color: $colorSilverChalice;
}

.nameSection {
    margin-bottom: 1.5rem;
}

.toggle {
    margin-bottom: 1rem;
}

.buttons {
    margin-top: 2rem;
    display: flex;
    align-items: center;

    @include media('<=phone') {
        flex-wrap: wrap;
    }
}

.saveButton {
    margin-right: 1.5rem;
}

.tooltip {
    vertical-align: middle;
    margin-left: 0.5rem;
}

.deleteButton {
    margin-left: auto;
    font-weight: $fontWeightBold;

    @include media('<=phone') {
        display: block;
        flex: 0 0 100%;
        margin-top: 2rem;
    }
}
