@import '../../../styles/layout';
@import '../../../styles/colors';
@import '../../../styles/rwd';

.replacement-modal-button {
    padding: 10px;
    @include media('<=phone') {
        font-size: 1.4rem;
    }
}

.replacement-modal {
    display: flex;
    padding: 20px;
    justify-content: center;
    flex-direction: column;
}

.replacement-modal-title {
    margin-top: 3rem;
    margin-bottom: 2rem;
}
