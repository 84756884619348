@import '../../../../styles/colors';

.panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.option-name {
    font-size: 1.2rem;
}

.option-price {
    font-size: 1.2rem;
    margin-left: auto;
}

.option-status {
    margin-left: 1rem;
}

.action-button {
    margin-top: 1rem;
    margin-right: 1rem;
}

.features-list {
    padding-left: 2rem;
    font-size: 1.2rem;

    & > li:not(:last-of-type) {
        margin-bottom: 1rem;
    }
}
li.pending-explanation {
    color: $colorGoldTips;
    font-style: italic;
}

.title {
    font-weight: bold;
    margin-bottom: 0.5rem;
}
