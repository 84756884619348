.registration-details {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    & > * {
        flex: 0 0 33.3%;
        margin-bottom: 1.5rem;
    }
}
