@import '../../../styles/colors';

.warning {
    position: absolute;
    bottom: -1rem;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.has-warning {
    margin-bottom: 4.5rem;
    color: $colorRedOrange;
}
.link {
    font-family: Lato !important;
    cursor: pointer;
    font-weight: bold;

    &:hover {
        text-decoration: underline;
    }
}
