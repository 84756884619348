@import '../../../styles/colors';
@import '../../../styles/fonts';

.root {
    position: relative;
}

.button {
    padding: 0;
    width: 4rem;
}

.arrow-left {
    transform: rotate(90deg);
}

.arrow-right {
    transform: rotate(-90deg);
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.input {
    margin: 0 1rem;
}

.calendar-wrapper {
    position: absolute;
    top: calc(100% + 1rem);
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
}

// Styles for 3rd party calendar
.calendar-component {
    border: 1px solid $colorAlto;
    box-shadow: 0 2px 1rem 0 rgba($colorCodGray, 0.15);
    border-radius: 1rem;
    padding: 2rem 3rem;

    :global {
        & .react-calendar__month-view__weekdays__weekday {
            color: $colorSilverChalice;
            font-size: 1.2rem;
            font-weight: 400;
            text-transform: capitalize;

            abbr {
                text-decoration: none;
            }
        }

        & .react-calendar__tile {
            border-radius: 0.5rem;
            color: $colorCodGray;

            &:hover {
                background: $colorWildSand;
            }

            &:disabled {
                background: $colorWhite;
                color: $colorAlto;
                cursor: not-allowed;
            }

            &:focus,
            &:active,
            &--active,
            &--active:enabled:hover {
                background: $colorFlamingo;
                border-radius: 0.5rem;
                color: $colorWhite;
                font-weight: $fontWeightBold;
            }

            &--now {
                border: 1px solid $colorFlamingo;
            }
        }

        & .react-calendar__month-view__days__day {
            font-size: 1.2rem;

            &--weekend {
            }

            &--neighboringMonth {
                color: $colorAlto;
            }
        }

        & .react-calendar__navigation__label {
            font-size: 1.2rem;
            font-weight: $fontWeightBold;
            text-transform: capitalize;

            &:enabled:hover {
                background: $colorWildSand;
                border-radius: 0.5rem;
            }
        }

        & .react-calendar__navigation button {
            cursor: pointer;

            &:hover {
                background: $colorWildSand;
            }

            &:disabled {
                background: $colorWhite;
                color: $colorAlto;
            }
        }
    }
}
