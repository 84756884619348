@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.map-controls {
    position: relative;

    & > *:not(:last-of-type) {
        margin-bottom: 3.5rem;

        @include media('<=phone') {
            margin-top: 1rem;
            margin-bottom: 0;
        }
    }
}

.live-mode-button {
    padding: 0;
}
.layerSelect {
    position: absolute;
    top: 0;
    right: 5rem;
    box-shadow: 0 2px 10px 0 rgba($colorCodGray, 0.15);
    animation: slideFromRight 0.3s;

    @include media('<=phone') {
        overflow: auto;
        height: calc(100vh - 20rem);
        width: calc(100vw - 10rem);
        display: flex;
        flex-direction: column;
    }
}

@keyframes slideFromRight {
    from {
        transform: translateX(20px);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.refreshing {
    opacity: 0.7;
    box-shadow: none;

    & svg {
        -webkit-animation: rotating 2s linear infinite;
        -moz-animation: rotating 2s linear infinite;
        animation: rotating 2s linear infinite;
    }

    &:hover {
        box-shadow: none;
        cursor: initial;
    }
}

.group-buttons {
    display: flex;
    flex-direction: column;

    & button {
        margin: 0.2rem 0;
    }
}
.slider {
    overflow: hidden;
    display: flex;
    justify-content: center;
    right: 0;
    margin-top: auto; //Push to bottom
    margin-bottom: 6rem; // Space left from bottom
    padding: 1rem 3rem;
    border-radius: 2rem;
    background-color: white;
    box-shadow: 0 2px 10px 0 rgba($colorCodGray, 0.15);
    transition-property: width;
    transition-duration: 2s;
    transition-timing-function: ease-in;
    transition-delay: 0.5s;
    position: absolute;
    bottom: 1.2rem;

    @include media('<=phone') {
        position: absolute;
        z-index: 800;
        bottom: 1rem;
        margin-bottom: 15rem;
        padding: 1rem 2.5rem;
    }
}
.premium-modal {
    white-space: break-spaces;
    text-align: center;

    button {
        padding: 0 2rem;

        &:last-of-type {
            font-size: 1.4rem;
            color: grey;
        }
    }
}
.textContainer {
    text-align: center;
    margin-bottom: 1rem;
}

.title {
    font-size: 2rem;
}
.buttonContainer {
    display: flex;
    flex-flow: column;
    align-items: center;
}
.button {
    width: 50%;
    height: 4rem !important;
}
.buttonCancel {
    height: 4rem !important;
    width: 50%;
    color: #ed6e18 !important;
    background: #ed6e1810 !important;
}
