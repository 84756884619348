@import '../../../styles/colors';

.headline {
    margin-bottom: 1.5rem;
}

.section {
    margin-bottom: 1.5rem;
    padding-bottom: 2.5rem;
    border-bottom: 1px solid $colorAlto;

    &:last-of-type {
        border-bottom: none;
    }
}

.params-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.buttons-container {
    display: flex;

    & > * {
        margin-right: 1rem;
    }
}
