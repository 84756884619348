@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/rwd';

.link {
    color: $colorFlamingo;
    font-weight: $fontWeightBold;
    display: inline-block;
    cursor: pointer;

    & > * {
        vertical-align: middle;
    }
}

.icon {
    transform: rotate(90deg);
    margin-right: 0.5rem;
}

.black {
    color: $colorCodGray;
}

.children {
    display: inline-block;
}

.collapseOnMobile {
    @include media('<=phone') {
        .icon {
            height: 1.3rem;
            width: 1.3rem;
        }
        .children {
            display: none;
        }
    }
}
