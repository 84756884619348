@import '../../../styles/colors';
@import '../../../styles/fonts';

.root {
    border: 1px solid $colorAlto;
    border-radius: 1rem;
    padding: 1.5rem;
}

.headline {
    margin-bottom: 2rem;
}

.row {
    display: flex;
    margin-bottom: 1rem;
    align-items: flex-end;
}

.label {
    width: 12rem;
    flex: 0 0 12rem;
    margin: 0;
}

.value {
    flex: 1 1 auto;
    font-weight: $fontWeightBold;
    font-size: 1.4rem;
    margin: 0;

    &.big {
        font-size: 2rem;
    }
}
