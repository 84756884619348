@import '../../../styles/colors';
@import '../../../styles/rwd';

.headline {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    @include media('<=phone') {
        margin-top: 2rem;
    }
}
.headline-biogaran {
    font-family: Lato !important;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    font-family: 'Helvetica Neue';
    font-weight: 700;
    font-style: normal;
    line-height: 24px;
    size: 20px;
    font-weight: bold;
    @include media('<=phone') {
        margin-top: 8rem;
    }
}
.resiliationAsked {
    color: $colorFlamingo;
    font-weight: bold;
}
.hideOnPhone {
    @include media('<=phone') {
        display: none;
    }
}
.not-cancelable {
    font-style: italic;
    color: $colorButtonDisabled;
    margin: 0;
    margin-bottom: 1.4rem;
}

.top-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $colorAlto;
    padding: 0.5rem 0;
}
.middle-row {
    border-top: 1px solid $colorAlto;
    margin: 2rem 0;
}
.bottom-row {
    border-top: 1px solid $colorAlto;
    margin-top: 2rem;

    button {
        margin: 2rem 0;
    }
}
.plan-name {
    margin-right: 2rem;
    font-size: 1.4rem;
}
.validate {
    padding: 1rem 0.5rem;
    @include media('<=phone') {
        width: -webkit-fill-available;
    }
}
.check-section {
    margin: 1rem 0;
}

.modal-buttons-wrapper {
    max-width: 42.5rem;
    margin: 0 auto;
}

.grid-with-margin {
    margin-bottom: 3.5rem;
}

.cancel-link {
    display: block;
}

.subscription-period {
    font-size: 1.4rem;
    margin: 1rem 0 0.5rem;
}
.single-offer {
    margin-bottom: 1rem;
    cursor: pointer;
}
.info {
    float: right;
}
.textColor {
    color: $colorFlamingo;
    cursor: pointer;
    font-size: 1.2;
}
.modal {
    width: 35rem !important;
}
.paddingNone {
    padding: initial;
}
.sizeIcon {
    width: 2rem;
    height: 2rem;
}
.sub-dates {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
