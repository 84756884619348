@import '../../../../styles/colors';

.tracker-details {
}

.label {
    display: block;
    font-size: 1.2rem;
    color: $colorSilverChalice;
}

.attribute {
    display: block;
    font-size: 1.2rem;
    margin-top: 0.5rem;
}
