@import '../../../styles/colors';

.section {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid $colorAlto;

    &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }

    &:first-of-type {
        padding-top: 0;
    }
}

.checkboxes {
    display: flex;
    position: relative;
    align-items: baseline;
    justify-content: space-between;

    & > * {
        flex: auto;
    }
}

.toggle-section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.submit {
    width: 20rem;
    margin-top: 3rem;
}
