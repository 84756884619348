@import '../../../styles/colors';

.controls {
    display: flex;
    align-items: center;
    min-height: 8rem;
}

.secondary {
    margin-left: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
}

.toggle {
    padding: 0.25rem;
}

.play-pause {
}

.eventTime {
    margin-left: auto;

    svg {
        color: $colorSilverChalice;
        vertical-align: middle;
        display: inline-block;
        margin-right: 1rem;
    }
}
