@import '../../../styles/colors';
@import '../../../styles/rwd';

.option {
    &:not(:last-of-type) {
        margin-bottom: 0.5rem;
    }
}

.cardExpiredModal {
    min-width: 80rem;
    @include media('<=phone') {
        min-width: 35rem;
    }
}
