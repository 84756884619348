@import '../../../../styles/colors';

.card {
    background: $colorWhite;
    border-radius: 10px;
    padding: 1.5rem;
}

.top-row {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $colorAlto;
}

.cross-icon {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    cursor: pointer;
}

.bottom-row {
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
    padding-top: 2rem;

    & > :first-child {
        margin-right: 1rem;
        flex: 0 0 4rem;
    }
}
